import React from "react";
import DraggableList from "react-draggable-list";
import { observer } from "mobx-react";

type DragListProps = {
  list: any[];
  itemKey: string | ((item: any) => string);
  template: new (props: any, context?: any) => React.Component;
  onMoveEnd?: (newList: readonly any[], movedItem: any, oldIndex: number, newIndex: number) => void;
  commonProps?: {};
  container?: () => HTMLElement | null | undefined;
  reverse?: boolean;
};

export const DragList = observer(({ list, itemKey, template, onMoveEnd, commonProps, container, reverse }: DragListProps) => {
  const _onMoveEnd = (newList: readonly any[], movedItem: any, oldIndex: number, newIndex: number) => {
    if (!onMoveEnd) {
      return;
    }
    const reverseIndex = (index: number) => newList.length - 1 - index;
    reverse
      ? onMoveEnd([...newList].reverse(), movedItem, reverseIndex(oldIndex), reverseIndex(newIndex))
      : onMoveEnd(newList, movedItem, oldIndex, newIndex);
  };

  return (
    <DraggableList
      list={reverse ? [...list].reverse() : [...list]}
      itemKey={itemKey}
      template={template}
      onMoveEnd={_onMoveEnd}
      commonProps={commonProps}
      container={container}
      springConfig={{ stiffness: 1000, damping: 50 }}
    />
  );
});
