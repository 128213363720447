import React, {Key, ReactNode} from "react";
import {observer} from "mobx-react";
import {Button, Table} from "antd";
import {List, PaginatedList} from "../../mvvm/lists"
import {TableProps} from "antd/lib/table";
import {SorterResult, TableCurrentDataSource, TablePaginationConfig, TableRowSelection} from "antd/lib/table/interface";
import {bindToCommand} from "@mvvm/commands";
import * as styles from "./AntTable.less";
import classNames from "classnames";

export interface TableColumnProps<TRecord> {
  title: string,
  canSort?: boolean,
  dataIndex: keyof TRecord,
  render?: (value: any, item: TRecord) => ReactNode,
  fixed?: "left" | "right",
  align?: "center" | "left" | "right",
  width?: string | number,
  visible?: boolean
}

type Props<T extends { id: number | string }> = {
  list: List<T>,
  selection?: boolean,
  columns: TableColumnProps<T>[]
};

function AntTableImpl<T extends { id: string | number }>({list, selection, columns, ...props}: Props<T> & Omit<TableProps<T>, "rowSelection" | "dataSource" | "rowKey" | "columns">) {

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys: list.selection.map(i => i.id),
    onChange: (selectedRowKeys: Key[], selectedRows: T[]) => list.selection = selectedRows
  };

  return <Table {...props}
                className={classNames(
                  styles.AntTable,
                  props.className
                )}
                rowKey={(r: T) => r.id}
                dataSource={list.items as []}
                rowSelection={selection ? rowSelection : undefined}
                onChange={(pagination: TablePaginationConfig, filters: Record<string, Key[] | null>, sorter: SorterResult<T> | SorterResult<T>[], extra: TableCurrentDataSource<T>) => {
                  if (!Array.isArray(sorter)) {
                    list.sortOrder = sorter ? {field: sorter.field?.toString(), direction: sorter.order?.toString()} as any : undefined;
                  }
                }}
                columns={columns.map(c => ({
                  title: c.title,
                  dataIndex: c.dataIndex as string,
                  render: c.render,
                  sorter: !!c.canSort,
                  sortOrder: list.sortOrder?.field == c.dataIndex ? list.sortOrder?.direction : undefined,
                  fixed: c.fixed,
                  align: c.align,
                  width: c.width,
                  visible: c.visible
                }))}/>;
}

type PaginatedProps<T extends { id: number | string }> = {
  list: PaginatedList<T>
  selection?: boolean,
  columns: TableColumnProps<T>[]
};

function AntPaginatedTableImpl<T extends { id: number | string }>({list, ...props}: PaginatedProps<T> & Omit<TableProps<T>, "rowSelection" | "dataSource" | "rowKey" | "pagination" | "loading" | "columns">) {
  return <AntTableImpl
    {...props}
    list={list}
    pagination={false}
    loading={list.isLoading}
    components={{
      body: {
        wrapper: ({children, className}: any) => (
          <>
            <tbody className={className}>
            {children}
            </tbody>
            <TableFooter list={list} span={props.columns.length + (props.selection ? 1 : 0)}/>
          </>
        )
      }
    }}/>
}

const TableFooter = observer(({list, span}: { list: PaginatedList<any>, span: number }) => (
  <>
    {list.hasNextPage &&
    <tfoot className="ant-table-footer">
      <tr>
        <td className="ant-table-cell" colSpan={span}>
          <Button type="link" size={"large"} style={{paddingLeft: 0}} {...bindToCommand(list.loadNextPage)}>Load more</Button>
        </td>
      </tr>
    </tfoot>
    }
  </>
));

export const AntTable = observer(AntTableImpl);
export const PaginatedAntTable = observer(AntPaginatedTableImpl);