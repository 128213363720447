import React from "react";
import {ApiBound, isLoaded} from "@mvvm/index";
import {UserDto} from "@api/Users/Model/UserDto";
import {UserPanelView} from "./UserPanel/UserPanelView";
import {authentication} from "../../../auth/Authentication";

interface IHeaderMenuViewProps {
  user: ApiBound<UserDto>;
  handleVisibleChange: () => void;
  onUserAccountClick: () => void;
}

export function HeaderMenuView(props: IHeaderMenuViewProps) {

  const onLogout = async () => {
    props.handleVisibleChange();
    await authentication.logout();
  };

  return (
    <React.Fragment>
      <div>
        {isLoaded(props.user) && (
          <UserPanelView
            onLogout={onLogout}
            onUserAccountClick={props.onUserAccountClick}
            name={props.user.name}
            email={props.user.emailAddress}
          />
        )}
      </div>
    </React.Fragment>
  );
}
