import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";
import { download } from "../../api";
import {ListOptions} from "../GenericList/ListOptions"
import {ServiceFilter} from "./ServiceFilter"

import {ServiceListItemDto} from "./Model/ServiceListItemDto"

export interface GetServiceListRequest {
    itemId?: number
    options?: ListOptions
    filter?: ServiceFilter
}
export const GetServiceListRequestMetadata: {[key in keyof Required<GetServiceListRequest>]: GetServiceListRequestPropertyMetadata} = {
    itemId: {
        $name: "itemId",
        $type: "NorskGjenbruk.Model.Reference<NorskGjenbruk.Model.Service>",
        dtoOptional: true,
    },
    options: {
        $name: "options",
        $type: "NorskGjenbruk.Server.Api.GenericList.ListOptions",
        dtoOptional: true,
    },
    filter: {
        $name: "filter",
        $type: "NorskGjenbruk.Server.Api.Services.ServiceFilter",
        dtoOptional: true,
    },
}
export interface GetServiceListRequestPropertyMetadata {
    $name: string
    $type: string
    dtoOptional?: boolean;
}
export const getServiceList = (getServiceListRequest: GetServiceListRequest): Promise<ServiceListItemDto[] | ApiErrorResponse> => {
    return send("GetServiceList", getServiceListRequest, false)
}
export const getServiceListBlob = (getServiceListRequest: GetServiceListRequest, accept: string): Promise<Blob> => {
    return download("GetServiceList", getServiceListRequest, accept);
}
