import { Command } from "./Command";
import { ContextualCommand } from "./ContextualCommand";
import { MouseEvent, KeyboardEvent } from "react";

export * from "./Command";
export * from "./ContextualCommand";

export function bindToCommand(command: Command<any>) {
  return {
    onClick: (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      return command.execute();
    },
    disabled: !command.isEnabled
  };
}

export function handleSubmit(command: Command) {
  return {
    onKeyDown: (e: KeyboardEvent<Element>) => {
      if (e.key === "Enter" && command.isEnabled) {
        command.execute();
      }
    }
  };
}

export function bindToContextualCommand<T>(command: ContextualCommand<T>, context: T) {
  return {
    onClick: (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      return command.execute(context);
    },
    disabled: !command.canExecute(context)
  };
}

export function bindMultiselectToContextualCommand(selectCommand: ContextualCommand<any>, deselectCommand: ContextualCommand<any>) {
  return {
    onSelect: (value: any) => {
      return selectCommand.execute(value);
    },
    onDeselect: (value: any) => {
      return deselectCommand.execute(value);
    },
    disabled: !selectCommand.canExecute(undefined) || !deselectCommand.canExecute(undefined)
  };
}
