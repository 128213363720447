import {Page} from "@mvvm/Page";
import {Command} from "@mvvm/commands";
import {observable} from "mobx";
import {resetPassword, ResetPasswordRequest, ResetPasswordRequestPropertyMetadata,} from "@api/Auth/ResetPasswordRequest";
import {isApiErrorResponse} from "../../../../Utils";
import {alertDialog} from "@mvvm/dialogs";
import {BindableForm, bindableForm} from "@mvvm/forms";
import {areEqual} from "@mvvm/forms/validators";

export class ResetPasswordPage extends Page {

  @observable submitted: boolean = false;

  resetPasswordRequest: ResetPasswordRequest = {
    userId: "",
    resetToken: "",
    password: "",
  };

  form: BindableForm<ResetPasswordRequest, any> = bindableForm<ResetPasswordRequest>()
    .addField("password", {validator: this.passwordValidator})
    .addCustomField("passwordToConfirm", async dto => "", dto => {
    }, {
      validator: areEqual(() => this.form.fields.password.value, "Passwords does not match")
    })
    .bindTo(() => this.resetPasswordRequest);

  constructor(public userId: string,
              public token: string,
              public goToLoginPage: Command,
              public passwordValidator: (p: any | undefined) => true | string[]) {
    super();
    this.submitted = false;
    this.resetPasswordRequest = {
      ...this.resetPasswordRequest,
      userId: userId,
      resetToken: token
    };
  }

  sendRequestFunc = async () => {

    if (await this.form.validateAndCommit()) {
      const res = await resetPassword(this.resetPasswordRequest);
      if (isApiErrorResponse(res)) {
        this.showModal(alertDialog("Error", res.errors.join("\n"), false));
      } else {
        this.submitted = true;
      }
    }
  };
}