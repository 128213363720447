import * as React from "react";
import {observer} from "mobx-react";
import * as styles from './StepsFooter.less';
import {Steps} from 'antd';
import {CreateOrderPage} from "../../CreateOrderPage";
import {bindToCommand} from "@mvvm/commands";
import {SubmitButton} from "../../../../../components/SubmitButton/SubmitButton";
import {ArrowRightLong, IconClose} from "../../../../../components/Icons/Icons";

const {Step} = Steps;

export const StepsFooter = observer(({model}: { model: CreateOrderPage }) => (
  <div className={styles.StepsFooter}>
    <button className={styles.DiscardButton} {...bindToCommand(model.dismiss)}>
      <IconClose className={styles.DiscardIcon}/>
      Discard
    </button>
    <Steps className={styles.Steps} size={"small"} current={model.step}>
      {model.steps.map((step, stepIndex) => (
        <Step className={styles.Step} onClick={() => step.displayed && model.setStep(stepIndex)} key={step.header} title={step.header}/>))}
    </Steps>
    {!model.isLastStep && <SubmitButton {...bindToCommand(model.nextStep)} text={"Next step"} icon={<ArrowRightLong/>}/>}
    {model.isLastStep && <SubmitButton {...bindToCommand(model.submitOrder)} text={"Process an order"}/>}
  </div>
));
