import { Page } from "@mvvm/Page";
import { action, computed, observable } from "mobx";
import { ApiBound, bindToApi, isLoaded, property } from "@mvvm/index";
import { getCustomerAgentRentalOrderDetails } from "@api/Orders/RentalOrders/GetCustomerAgentRentalOrderDetailsRequest";
import { CustomerAgentRentalOrderDetailsDto } from "@api/Orders/RentalOrders/Model/CustomerAgentRentalOrderDetailsDto";
import { RentalOrderStatus } from "@externals";
import { Command } from "@mvvm/commands";
import { deleteRentalOrder } from "@api/Orders/RentalOrders/DeleteRentalOrderRequest";
import { throwOnApiError } from "apiClient";

export class RentalOrderDetailsPage extends Page {
  deleteOrder: Command<void>;
  @observable
  order: ApiBound<CustomerAgentRentalOrderDetailsDto> = "Loading";
  @computed
  private get canDeleteOrder() {
    return isLoaded(this.order) && this.order.status === RentalOrderStatus.new;
  }
  @computed
  public get showDeleteBtn() {
    return this.canDeleteOrder || (isLoaded(this.order) && this.order.status === RentalOrderStatus.confirmed);
  }

  constructor(public orderId: number, public close: () => void, public readonly inProcessRentalOrderStatuses: RentalOrderStatus[]) {
    super();
    bindToApi(property(this, "order"), () => getCustomerAgentRentalOrderDetails({ id: this.orderId }));
    this.deleteOrder = new Command<void>(this.deleteOrderFunc, () => this.canDeleteOrder);
  }

  @action.bound
  private async deleteOrderFunc() {
    await throwOnApiError(deleteRentalOrder({ orderId: this.orderId }));
    this.close();
  }
}
