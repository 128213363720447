import * as React from 'react';
import Icon from '@ant-design/icons';
import MenuSvg from '../../assets/icons/icon-menu.svg';
import CloseSvg from '../../assets/icons/icon-close.svg';
import OrderPlaced from '../../assets/icons/order-placed.svg';
import Trash from '../../assets/icons/icon-trash.svg';
import ArrowRight from '../../assets/icons/icon-arrow-right.svg';

interface ISvgIcon extends React.HTMLAttributes<HTMLDivElement>{}

export const IconMenu = (props:ISvgIcon) => <Icon
  component={() => <MenuSvg/>} {...props}
/>;

export const IconClose = (props:ISvgIcon) => <Icon
  component={() => <CloseSvg/>} {...props}
/>;

export const OrderPlacedBadge = (props:ISvgIcon) => <Icon
  component={() => <OrderPlaced/>} {...props}
/>;

export const ArrowRightLong = (props:ISvgIcon) => <Icon
  component={() => <ArrowRight/>} {...props}
/>;

export const TrashCar = (props:ISvgIcon) => <Icon
  component={() => <Trash/>} {...props}
/>;