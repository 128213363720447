import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ResetPasswordRequest {
    userId: string
    resetToken: string
    password: string
}
export const ResetPasswordRequestMetadata: {[key in keyof Required<ResetPasswordRequest>]: ResetPasswordRequestPropertyMetadata} = {
    userId: {
        $name: "userId",
        $type: "string",
        required: true,
    },
    resetToken: {
        $name: "resetToken",
        $type: "string",
        required: true,
    },
    password: {
        $name: "password",
        $type: "string",
        required: true,
    },
}
export interface ResetPasswordRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const resetPassword = (resetPasswordRequest: ResetPasswordRequest): Promise<any | ApiErrorResponse> => {
    return send("ResetPassword", resetPasswordRequest, true)
}
