
export interface CustomerAgentRentalOrderProductLineDto {
    id: number
    productName: string
    quantity: number
    returnedQuantity: number
    remainingQuantity: number
}
export const CustomerAgentRentalOrderProductLineDtoMetadata: {[key in keyof Required<CustomerAgentRentalOrderProductLineDto>]: CustomerAgentRentalOrderProductLineDtoPropertyMetadata} = {
    id: {
        $name: "id",
        $type: "int",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    productName: {
        $name: "productName",
        $type: "string",
        gridColumn: {
            header: "Product",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    quantity: {
        $name: "quantity",
        $type: "int",
        gridColumn: {
            header: "Rented",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    returnedQuantity: {
        $name: "returnedQuantity",
        $type: "int",
        gridColumn: {
            header: "Returned",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    remainingQuantity: {
        $name: "remainingQuantity",
        $type: "int",
        gridColumn: {
            header: "Remaining",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
}
export interface CustomerAgentRentalOrderProductLineDtoPropertyMetadata {
    $name: string
    $type: string
    gridColumn?: {
        hidden?: boolean;
        header?: string;
    },
    sieve?: {
        canFilter?: boolean;
        canSort?: boolean;
    },
}
