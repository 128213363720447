
export interface CustomerAgentRentalOrderServiceLineDto {
    id: number
    serviceName: string
    quantity: number
}
export const CustomerAgentRentalOrderServiceLineDtoMetadata: {[key in keyof Required<CustomerAgentRentalOrderServiceLineDto>]: CustomerAgentRentalOrderServiceLineDtoPropertyMetadata} = {
    id: {
        $name: "id",
        $type: "int",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    serviceName: {
        $name: "serviceName",
        $type: "string",
        gridColumn: {
            header: "Service",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    quantity: {
        $name: "quantity",
        $type: "int",
        gridColumn: {
            header: "Quantity",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
}
export interface CustomerAgentRentalOrderServiceLineDtoPropertyMetadata {
    $name: string
    $type: string
    gridColumn?: {
        hidden?: boolean;
        header?: string;
    },
    sieve?: {
        canFilter?: boolean;
        canSort?: boolean;
    },
}
