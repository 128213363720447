import {Page} from "@mvvm/Page";
import {Command} from "@mvvm/commands";
import {observable} from "mobx";
import {forgotPassword} from "@api/Auth/ForgotPasswordRequest";
import {isApiErrorResponse} from "../../../../Utils";
import {alertDialog} from "@mvvm/dialogs";
import {ForgotPasswordRequest} from "@api/Auth/ForgotPasswordRequest";
import {bindableForm} from "@mvvm/forms";
import {email} from "@mvvm/forms/validators";

export class ForgotPasswordPage extends Page {

  @observable submitted: boolean = false;

  ForgotPasswordRequest: ForgotPasswordRequest = {
    emailAddress: "",
  };

  form = bindableForm<ForgotPasswordRequest>()
    .addField("emailAddress", { validator: email() })
    .bindTo(() => this.ForgotPasswordRequest);

  constructor(public goToLoginPage: Command) {
    super();
    this.submitted = false;
  }

  sendRequestFunc = async () => {
    if (await this.form.validateAndCommit()) {
      const res = await forgotPassword(this.ForgotPasswordRequest);
      if (isApiErrorResponse(res)) {
        this.showModal(alertDialog("Error", res.errors.join("\n"), false));
      } else {
        this.submitted = true;
      }
    }
  }
}