import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ForgotPasswordRequest {
    emailAddress: string
}
export const ForgotPasswordRequestMetadata: {[key in keyof Required<ForgotPasswordRequest>]: ForgotPasswordRequestPropertyMetadata} = {
    emailAddress: {
        $name: "emailAddress",
        $type: "string",
        required: true,
    },
}
export interface ForgotPasswordRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const forgotPassword = (forgotPasswordRequest: ForgotPasswordRequest): Promise<any | ApiErrorResponse> => {
    return send("ForgotPassword", forgotPasswordRequest, true)
}
