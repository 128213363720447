import {ApiErrorResponse} from "@api/Model/ApiErrorResponse";
import {IObservableArray, runInAction} from "mobx";
import {Page} from "@mvvm/Page";
import moment from "moment";

export function isApiErrorResponse<T>(response: T | ApiErrorResponse): response is ApiErrorResponse {
  return isObject(response) && (<ApiErrorResponse>response).errors !== undefined;
}

export function catchErrors<T, K>(f: (args: K) => Promise<T | ApiErrorResponse>) {
  return async function (args: K) {
    const r = await f(args);
    if (isApiErrorResponse(r)) {
      console.log("Error", r);
      throw r;
    } else
      return r;
  }
}

export const downloadBlob = async (blob: Blob, fileName: string) => {
  const itemsBlobUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.download = fileName;
  a.href = itemsBlobUrl;
  a.target = "_blank";
  a.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
  a.remove();
};

export function getFileNameFromContentDispositionHeader(headerValue: string | null) {
  if (!headerValue) return null;
  try {
    return headerValue
      .split(";")
      .map(x => x.trim())
      .filter(x => x.startsWith("filename="))[0]
      .substr("filename=".length)
      .replace(/\"/g, "");
  } catch {
    return null;
  }
}

export function isObject(val: any): boolean {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
}

export const previewUrlService = {
  path: "/preview/",
  isPreviewLink(pathname: string) {
    return pathname.startsWith(this.path);
  },
  getPreviewToken(pathname: string) {
    return pathname.substring(this.path.length);
  },
  getPreviewUrl(token: string) {
    return location.origin + this.path + token;
  }
};

export function getDraggedItemStyle(itemSelected: number, anySelected?: number) {
  const scale = itemSelected * 0.02 + 1;
  const shadow = itemSelected * 15 + 1;

  const itemSelectedPart = itemSelected
    ? {transform: `scale(${scale})`, boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`}
    : {};

  const anySelectedPart = anySelected ? {maxHeight: "100%"} : {};

  return {
    style: {...itemSelectedPart, ...anySelectedPart},
    content: {
      transformOrigin: "30% 50% 0px"
    }
  };
}

export function syncArrays<T1, T2>(
  source: IObservableArray<T1>,
  target: T2[],
  create: (s: T1) => T2,
  comparator: (sourceElement: T1) => (targetElement: T2) => boolean
): void {
  source.observe(change => {
    if (change.type !== "splice") {
      return;
    }

    const copy = target.slice();
    for (let i = 0; i < change.object.length; i++) {
      const layout = change.object[i];
      target[i] = copy.find(comparator(layout)) || create(layout);
    }
    target.length = change.object.length;
  });
}

// WARNING: This should not be exported as it does not work in a generic case, see:
// https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208
export function getOwnPropertyNames<T>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

// logging

const loggers: { [name: string]: boolean } = {};

export function loggerFactory(loggerName: string) {
  if (loggerName === "all") {
    console.error("Reserved logger name: 'all'");
    return () => {
    };
  }

  return (...a: any[]) => {
    if (loggers.all && loggers[loggerName]) {
      console.log(`[${loggerName}]`, ...a);
    }
  };
}

export function enableLogging() {
  loggers.all = true;
}

export function enableLogger(loggerName: string) {
  loggers[loggerName] = true;
}

export function roundToDecimalPlaces(value: number, decimalPlaces: number) {
  const multiplier = Math.pow(10, Math.round(decimalPlaces));
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export function moveArrayItemToNewIndex(array: any[], oldIndex: number, newIndex: number) {
  runInAction(() => {
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  });
}

export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function withDefaultPage<TDefaultPage>(page: Page, defaultPageFactory: () => Promise<TDefaultPage>) {
  if (!page.childPage) {
    defaultPageFactory.bind(page)()
  }
  return undefined;
}

export function toNorwegianNumberFormat(numberValue: number, digits: number) {
  const options = {
    style: 'decimal',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat('nb-NO', options).format(numberValue);
};

export function toNorwegianDateFormat(dateString: string) {
  const d = moment(dateString)
  if (!d.isValid() || d.year() == 1) return "-";
  return d.format("DD.MM.YYYY");
};
