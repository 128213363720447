import { observer } from "mobx-react";
import * as React from "react";
import { Modal } from 'antd';
import { IConfirmationDialog } from "./index";

@observer
export class AntConfirmation extends React.Component<{ dialog: IConfirmationDialog }> {

    componentDidMount() {
     Modal.confirm({
        title: this.props.dialog.title,
        content: (
          <div>
            <p>{this.props.dialog.content}</p>
          </div>
        ),
        onOk: () => this.props.dialog.yes(),
        onCancel: () => this.props.dialog.no()
      });
    }

    render() {
        return null;
    }
}