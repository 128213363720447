import { Page } from "@mvvm/Page";
import { Command } from "@mvvm/commands";
import { login, LoginRequest } from "@api/Auth/LoginRequest";
import { isApiErrorResponse } from "../../../../Utils";
import { TokenResult } from "../../../../data/Infrastructure/Auth/TokenResult";
import { alertDialog } from "@mvvm/dialogs";
import { bindableForm } from "@mvvm/forms";
import { email, required } from "@mvvm/forms/validators";

export class LoginPage extends Page {
  
  loginRequest : LoginRequest = {
    emailAddress: "",
    password: ""
  };
  
  form = bindableForm<LoginRequest>()
    .addField("emailAddress", { validator: email() })
    .addField("password", { validator: required() } )
    .bindTo(() => this.loginRequest);

  constructor(private onAuthenticated: (result: TokenResult) => Promise<void>,
              public goToForgotPasswordPage: Command) {
    super();
  }
  
  loginFunc = async () => {
    
    if (await this.form.validateAndCommit()) {
      const res = await login(this.loginRequest);
      if (!isApiErrorResponse(res)) {
        await this.onAuthenticated(res);
      }
      else {
        await this.showModal(alertDialog("Error", res.errors.join("\n"), false));
      }  
    }
  };
}