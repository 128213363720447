import * as React from "react";
import {observer} from "mobx-react";
import {AuthPage} from "./AuthPage";
import {ContentView} from "@mvvm/ContentView";
import {withDefaultPage} from "../../../Utils";
import {LoginPage} from "./Login/LoginPage";
import {Layout, Row, Col, Typography} from "antd";
import {CheckOutlined} from '@ant-design/icons';
import * as styles from "./AuthStyles.less";
import {Spacer} from "../../../components/Spacer/Spacer";
import {Logo} from "../../../components/Logo/Logo";
import logoSrc from "../../../assets/looping-logo.svg"

const {Text} = Typography;
const {Content} = Layout;

export const AuthPageView = observer(({model}: { model: AuthPage }) => (
  <Content className={styles.AuthPage}>
    <Layout>
      <Row className={styles.LayoutRow}>
        <Col className={styles.LayoutCol} md={12}>
          <div className={styles.ColumnContainer}>
            <div className={styles.LogoContainer}>
              <Logo src={logoSrc}/>
            </div>
            <h1 className={styles.MainHeader}>Welcome to Looping Customer Portal</h1>
            <Spacer>
              <ContentView content={model.childPage}/>
              {withDefaultPage<LoginPage>(model, model.navigateToLoginPage)}
            </Spacer>
          </div>
        </Col>
        <Col className={styles.LayoutCol} md={12}>
          <div className={styles.ColumnContainer}>
            {/*<h1 className={styles.ListHeader}>Looping is part of the consumer economy</h1>*/}
            {/*<TeaserList/>*/}
          </div>
        </Col>
      </Row>
    </Layout>
  </Content>
));

function TeaserList() {
  const whatWeDo = [
    "We replace disposable plastic with environmentally friendly transport packaging",
    "We identify problem areas that can be solved more effectively and develop practical and smart solutions",
    "We have design and development inhouse so that we can develop together with the customer with immediate results.",
  ];

  return (
    <ul className={styles.TeaserList}>
      {whatWeDo.map((text, index) =>
        <li key={`teaser-${index}`}>
          <CheckOutlined className={styles.Checked}/>
          <Text style={{color: "white"}}>{text}</Text>
        </li>
      )}
    </ul>
  );
}