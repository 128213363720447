import { FormFieldValidator } from "./formField";

function isEmpty(value: {} | string | number | undefined | null) {
  if (!value) return true;

  if (Array.isArray(value)) return value.length === 0;

  return value.toString().length === 0;
}

export const NullValidator = (value: any): true => true;

export function required(message?: string) {
  return (value: any | undefined) => {
    if (!isEmpty(value)) {
      return true;
    } else {
      return [message ?? "Field is required"];
    }
  };
}

export function validId<T>(idAccessor: (value: T) => number, message?: string) {
  return (value: any | undefined) => {
    if (!!value && idAccessor(value) > 0) {
      return true;
    } else {
      return [message ?? "Field is required"];
    }
  };
}

export function areEqual(valueAccessor: () => any | undefined, message: string) {
  return (value: any | undefined) => {
    return value === valueAccessor() ? true : [message];
  };
}

export function validateWhen<T>(validationPredicate: () => boolean, formFieldValidator: FormFieldValidator<T>) {
  return (value: any | undefined) => {
    const predicateResult = validationPredicate();
    if (predicateResult === true) {
      return formFieldValidator(value);
    } else {
      return true;
    }
  };
}

export function email(message?: string) {
  return (value: any | undefined) => {
    const re = /\S+@\S+\.\S+/;

    if (!isEmpty(value) && re.test(value.toString())) {
      return true;
    } else {
      return [message ?? "Enter valid e-mail"];
    }
  };
}

export function phone(message?: string) {
  return (value: any | undefined) => {
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/;

    if (!value) {
      return true;
    }

    return re.test(value.toString()) ? true : [message ?? "Enter valid phone number"];
  };
}

export function validators<T>(...validators: FormFieldValidator<T>[]) {
  return async (value: T) => {
    const result = await Promise.all(validators.map(v => v(value)));
    if (result.every(r => r === true)) return true;

    return Array.prototype.concat(
      [],
      result.filter(r => r !== true)
    );
  };
}
