import { observer } from "mobx-react";
import { Page } from "@mvvm/Page";
import { IAlertDialog, IConfirmationDialog, antDialogs } from "@mvvm/dialogs";
import { runInAction } from "mobx";
import { message } from "antd";
import * as React from "react";
import { IMessage } from "@mvvm/IMessage";
import { ReactNode } from "react";

export const PageView = observer(PageViewComponent);

function PageViewComponent<TDialog extends IConfirmationDialog | IAlertDialog>({ page, children } : { page : Page<TDialog>, children : ReactNode }){
    return (<>
        { children }
        { antDialogs(page) }
        <AntMessages messages={page.messages} />
    </>);
}

const AntMessages = observer(({ messages }: { messages: IMessage[] }) => {
    if (messages.length) {
        setTimeout(() => {
            runInAction(() => {
                while (messages.length) {
                    const msg = messages.pop()!;
                    switch (msg.type) {
                        case "success":
                            message.success(msg.content);
                            break;
                        case "info":
                            message.info(msg.content);
                            break;
                        case "warning":
                            message.warning(msg.content);
                            break;
                        case "error":
                            message.error(msg.content);
                            break;
                    }
                }
            });
        }, 0);
    }
    return <></>;
});