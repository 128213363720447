import React from "react";
import * as styles from "./ResetIcon.less";
import { Command, bindToCommand } from "@mvvm/commands";
import { Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Label } from "../Label/Label";
import { CustomIcon } from "@components";

interface IResetIconProps {
  command: Command;
  tooltip?: string;
  label?: string;
  className?: string;
}

export const ResetIcon = observer(({ command, tooltip, label, className }: IResetIconProps) => {
  const icon = <div  {...bindToCommand(command)}><CustomIcon className={classNames(styles.icon, className)} type="close" /></div>;
  const labeledIcon = command.isEnabled && (
    <>
      {label ? (
        <Label text={label} reverse>
          {icon}
        </Label>
      ) : (
        icon
      )}
    </>
  );
  return <>{tooltip ? <Tooltip title={tooltip}>{labeledIcon || <></>}</Tooltip> : labeledIcon}</>;
});
