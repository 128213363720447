import React, { ReactElement } from "react";
import { PageView } from "./features/Pages/PageView";
import { Page } from "@mvvm/Page";

import { registerContentView } from "@mvvm/ContentView";
import { ApplicationShell } from "./features/Pages/ApplicationShell/ApplicationShell";
import { ApplicationShellView } from "./features/Pages/ApplicationShell/ApplicationShellView";
import { AuthPage } from "./features/Pages/Auth/AuthPage";
import { AuthPageView } from "./features/Pages/Auth/AuthPageView";
import { LoginPage } from "./features/Pages/Auth/Login/LoginPage";
import { LoginPageView } from "./features/Pages/Auth/Login/LoginPageView";
import { ActivateAccountPage } from "./features/Pages/Auth/ActivateAccount/ActivateAccountPage";
import { ActivateAccountPageView } from "./features/Pages/Auth/ActivateAccount/ActivateAccountPageView";
import { ForgotPasswordPage } from "./features/Pages/Auth/ForgotPassword/ForgotPasswordPage";
import { ForgotPasswordPageView } from "./features/Pages/Auth/ForgotPassword/ForgotPasswordPageView";
import { ResetPasswordPage } from "./features/Pages/Auth/ResetPassword/ResetPasswordPage";
import { ResetPasswordPageView } from "./features/Pages/Auth/ResetPassword/ResetPasswordPageView";
import { RentalOrdersPage } from "./features/Pages/RentalOrders/RentalOrdersPage";
import { RentalOrdersPageView } from "./features/Pages/RentalOrders/RentalOrdersPageView";
import { CreateOrderPage } from "./features/Pages/CreateOrder/CreateOrderPage";
import { CreateOrderPageView } from "./features/Pages/CreateOrder/CreateOrderPageView";
import { CreateOrderStep1Page } from "./features/Pages/CreateOrder/Steps/Step1/CreateOrderStep1Page";
import { CreateOrderStep1PageView } from "./features/Pages/CreateOrder/Steps/Step1/CreateOrderStep1PageView";
import { CreateOrderStep2Page } from "./features/Pages/CreateOrder/Steps/Step2/CreateOrderStep2Page";
import { CreateOrderStep2PageView } from "./features/Pages/CreateOrder/Steps/Step2/CreateOrderStep2PageView";
import { CreateOrderSummaryPage } from "./features/Pages/CreateOrder/Steps/Summary/CreateOrderSummaryPage";
import { CreateOrderSummaryPageView } from "./features/Pages/CreateOrder/Steps/Summary/CreateOrderSummaryPageView";
import { RentalOrderDetailsPage } from "./features/Pages/RentalOrders/RentalOrderDetailsPage";
import { RentalOrderDetailsPageView } from "./features/Pages/RentalOrders/RentalOrderDetailsPageView";

function pageView<T extends Page<any>>(view: ({ model }: { model: T }) => ReactElement, model: T) {
  return <PageView page={model}>{React.createElement(view, { model })}</PageView>;
}

export const viewRegistration = () => {
  registerContentView(ApplicationShell, content => pageView(ApplicationShellView, content));
  registerContentView(AuthPage, content => pageView(AuthPageView, content));
  registerContentView(LoginPage, content => pageView(LoginPageView, content));
  registerContentView(ActivateAccountPage, content => pageView(ActivateAccountPageView, content));
  registerContentView(ForgotPasswordPage, content => pageView(ForgotPasswordPageView, content));
  registerContentView(ResetPasswordPage, content => pageView(ResetPasswordPageView, content));
  
  registerContentView(CreateOrderPage, content => pageView(CreateOrderPageView, content));
  registerContentView(CreateOrderStep1Page, content => pageView(CreateOrderStep1PageView, content));
  registerContentView(CreateOrderStep2Page, content => pageView(CreateOrderStep2PageView, content));
  registerContentView(CreateOrderSummaryPage, content => pageView(CreateOrderSummaryPageView, content));

  registerContentView(RentalOrdersPage, content => pageView(RentalOrdersPageView, content));
  registerContentView(RentalOrderDetailsPage, content => pageView(RentalOrderDetailsPageView, content));
};
