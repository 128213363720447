import { route } from "@mvvm/routing";
import { ApplicationShell } from "./features/Pages/ApplicationShell/ApplicationShell";
import { AuthPage } from "./features/Pages/Auth/AuthPage";
import { LoginPage } from "./features/Pages/Auth/Login/LoginPage";
import { ForgotPasswordPage } from "./features/Pages/Auth/ForgotPassword/ForgotPasswordPage";
import { ResetPasswordPage } from "./features/Pages/Auth/ResetPassword/ResetPasswordPage";
import { ActivateAccountPage } from "./features/Pages/Auth/ActivateAccount/ActivateAccountPage";
import { CreateOrderPage } from "./features/Pages/CreateOrder/CreateOrderPage";
import { RentalOrdersPage } from "./features/Pages/RentalOrders/RentalOrdersPage";
import { RentalOrderDetailsPage } from "./features/Pages/RentalOrders/RentalOrderDetailsPage";

export const applicationShell = new ApplicationShell();
export const applicationShellRoute = route(() => applicationShell);

const authRoute = applicationShellRoute.addRoute<AuthPage>(
  "/auth",
  AuthPage,
  (shell, p) => shell.showAuthPage(),
  page => ({})
);

const loginRoute = authRoute.addRoute<LoginPage>(
  "/login",
  LoginPage,
  (page, p) => page.navigateToLoginPage(),
  page => ({})
);

const confirmEmailRoute = authRoute.addRoute<ActivateAccountPage, { userId: string; token: string }>(
  "/activate/:userId/:token",
  ActivateAccountPage,
  (page, p) => page.navigateToActivateAccountPage(p.userId, p.token),
  page => ({ userId: page.userId, token: page.token })
);

const forgotPasswordRoute = authRoute.addRoute<ForgotPasswordPage>(
  "/forgotPassword",
  ForgotPasswordPage,
  (page, p) => page.navigateToForgotPasswordPage(),
  page => ({})
);

const resetPasswordRoute = authRoute.addRoute<ResetPasswordPage, { userId: string; token: string }>(
  "/resetPassword/:userId/:token",
  ResetPasswordPage,
  (page, p) => page.navigateToResetPasswordPage(p.userId, p.token),
  page => ({ userId: page.userId, token: page.token })
);

const createOrderRoute = applicationShellRoute.addRoute<CreateOrderPage>(
  "/order",
  CreateOrderPage,
  (shell, p) => shell.navigateToCreateOrderPage(),
  page => ({})
);

const rentalOrdersRoute = applicationShellRoute.addRoute<RentalOrdersPage>(
  "/orders",
  RentalOrdersPage,
  (shell, p) => shell.navigateToRentalOrdersPage(),
  page => ({})
);

const rentalOrderDetailsRoute = rentalOrdersRoute.addRoute<RentalOrderDetailsPage, { orderId: string }>(
  "/:orderId",
  RentalOrderDetailsPage,
  (ordersPage, p) => ordersPage.showOrderDetails(parseInt(p.orderId)),
  page => ({orderId: (page.orderId || "").toString()})
);
