import * as React from "react";
import { Input, Switch } from "antd";
import { InputProps, TextAreaProps } from "antd/lib/input";
import { Key } from "ts-keycode-enum";
import { useState } from "react";
import { roundToDecimalPlaces } from "../Utils";

const AntTextArea = Input.TextArea;

interface TextInputPropsInterface<T> {
  value: T | undefined;
  onChange: (value: T) => void;
  onCommit?: () => void;
  type?: "text" | "password" | "email";
}

type TextInputProps<T, OmitT> = TextInputPropsInterface<T> & Omit<OmitT, "value" | "onChange">;

const getOnCommitMethods = (onCommit: (() => void) | undefined, blurOnEnter: boolean = true) => {
  return onCommit
    ? {
      onBlur: onCommit,
      onKeyPress: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (blurOnEnter && e.which === Key.Enter) {
          // @ts-ignore
          e.target.blur();
        }
      }
    }
    : {};
};

export function TextInput(props: TextInputProps<string, InputProps>) {
  const { value, onChange, onCommit, ...remainingProps } = props;
  return (
    <Input
      {...remainingProps}
      type={props.type || "text"}
      value={value}
      onChange={event => onChange(event.target.value)}
      {...getOnCommitMethods(onCommit)}
    />
  );
}

export function NumberInput(props: TextInputProps<number | undefined, InputProps>) {
  const { value, onChange, onCommit, ...remainingProps } = props;
  return (
    <Input
      {...remainingProps}
      type="number"
      value={value}
      onChange={event => {
        const parsedValue = parseFloat(event.target.value);
        onChange(isNaN(parsedValue) ? undefined : parsedValue);
      }}
      {...getOnCommitMethods(onCommit)}
    />
  );
}

export function IntegerInput(props: TextInputProps<number | undefined, InputProps>) {
  const { value, onChange, step, onCommit, ...remainingProps } = props;
  return (
    <Input
      {...remainingProps}
      type="number"
      step={props.step}
      value={(value !== undefined && value.toString()) || ""}
      onChange={event => {
        const parsedValue = parseFloat(event.target.value);
        const decimalPlaces = step ? Math.log10(1 / +step) : 0;
        let value = isNaN(parsedValue) ? undefined : roundToDecimalPlaces(parsedValue, decimalPlaces);
        if (value !== undefined && props.min !== null && props.min !== undefined) {
          const minValue = +(props.min);
          value = Math.max(minValue, value);
        }
        if (value !== undefined && props.max !== null && props.max !== undefined) {
          const maxValue = +(props.max);
          value = Math.min(maxValue, value);
        }
        onChange(value);
      }}
      {...getOnCommitMethods(onCommit)}
    />
  );
}

export function NullableIntegerInput(
  props: { showInputOn: "checked" | "unchecked"; labelOn?: string; labelOff?: string; defaultValue: number } & TextInputProps<number | null | undefined,
    InputProps>
) {
  const { value, onChange, defaultValue, step, showInputOn, labelOn, labelOff, onCommit, ...remainingProps } = props;
  const [prevValue, setPrevValue] = useState(value === null || value === undefined || value < 0 ? defaultValue : value);
  const inputProps = { value, step, onChange, onCommit, ...remainingProps };

  const isChecked = (value !== null && showInputOn === "checked") || (value === null && showInputOn === "unchecked");
  const showInput = isChecked === (showInputOn === "checked");
  return (
    <>
      <Switch
        checkedChildren={labelOn}
        unCheckedChildren={labelOff}
        checked={isChecked}
        onChange={() => {
          if (showInput) {
            setPrevValue(value === null || value === undefined || value < 0 ? defaultValue : value);
            onChange(null);
          } else {
            onChange(prevValue);
          }
          onCommit && onCommit();
        }}
      />
      {showInput && (
        // @ts-ignore
        <IntegerInput {...inputProps} />
      )}
    </>
  );
}

export function TextArea(props: TextInputProps<string, TextAreaProps>) {
  const { value, onChange, onCommit, ...remainingProps } = props;
  return (
    <AntTextArea
      {...remainingProps}
      value={value}
      onChange={event => onChange(event.target.value)}
      {...getOnCommitMethods(onCommit, false)}
    />
  );
}
