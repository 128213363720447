import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface GetOrdersAgentsFilterRequest {
}
export const GetOrdersAgentsFilterRequestMetadata: {[key in keyof Required<GetOrdersAgentsFilterRequest>]: GetOrdersAgentsFilterRequestPropertyMetadata} = {
}
export interface GetOrdersAgentsFilterRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getOrdersAgentsFilter = (getOrdersAgentsFilterRequest: GetOrdersAgentsFilterRequest): Promise<any | ApiErrorResponse> => {
    return send("GetOrdersAgentsFilter", getOrdersAgentsFilterRequest, false)
}
