import * as React from "react";
import * as styles from "./SidebarView.less";
import {Menu} from "antd";
import {CustomIcon} from "@components";
import {FlexView} from "../../components/FlexView";
import {ButtonNewOrder} from "./NewOrderButton/ButtonNewOrder";
import {Logo} from "../../components/Logo/Logo";
import logoSrc from "./../../assets/looping-logo-white.svg"

interface ISidebarView {
  isSidebarVisible: boolean,
  createOrder: () => void,
  onMenuItemClick: () => void
  navigateToOrdersPage: () => void
}

export const SidebarView = ({isSidebarVisible, createOrder, onMenuItemClick, navigateToOrdersPage}: ISidebarView) => (
  <div className={styles.Sidebar} style={{display: isSidebarVisible ? 'block' : 'none'}}>
    <FlexView column flex={1}>
      <div className={styles.LogoContainer}>
        <Logo src={logoSrc}/>
      </div>
      <FlexView className={styles.MenuContainer} flex={1} column justifyContent={'space-between'}>
        <Menu className={styles.Menu} theme={"dark"} onClick={onMenuItemClick}>
          <Menu.Item key="1" icon={<CustomIcon type={"icon-orders"}/>} onClick={navigateToOrdersPage}>
            Orders
          </Menu.Item>
        </Menu>
      </FlexView>
      <ButtonNewOrder createOrder={createOrder} text={"Create new order"}/>
    </FlexView>
  </div>
);