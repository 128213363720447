import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface GetOrdersDepartmentsFilterRequest {
}
export const GetOrdersDepartmentsFilterRequestMetadata: {[key in keyof Required<GetOrdersDepartmentsFilterRequest>]: GetOrdersDepartmentsFilterRequestPropertyMetadata} = {
}
export interface GetOrdersDepartmentsFilterRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getOrdersDepartmentsFilter = (getOrdersDepartmentsFilterRequest: GetOrdersDepartmentsFilterRequest): Promise<any | ApiErrorResponse> => {
    return send("GetOrdersDepartmentsFilter", getOrdersDepartmentsFilterRequest, false)
}
