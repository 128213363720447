import { Page } from "@mvvm/Page";
import { PaginatedList } from "@mvvm/lists";
import { catchErrors } from "../../../Utils";
import { getCustomerAgentRentalOrders } from "@api/Orders/RentalOrders/GetCustomerAgentRentalOrdersRequest";
import { action, computed, observable } from "mobx";
import { RentalOrderDetailsPage } from "./RentalOrderDetailsPage";
import moment from "moment";
import { SortDirection } from "@api/GenericList/SortDirection";
import { SortOption } from "@api/GenericList/SortOption";
import { RentalOrderStatus } from "@externals";
import { Command } from "@mvvm/commands";
import { ApiBound, bindToApi, ensureLoaded, isLoaded, property } from "@mvvm/index";
import { CustomerDepartmentDto } from "@api/Customers/Model/CustomerDepartmentDto";
import { CustomerAgentDto } from "@api/Customers/Model/CustomerAgentDto";
import { getOrdersAgentsFilter } from "@api/Customers/GetOrdersAgentsFilterRequest";
import { getOrdersDepartmentsFilter } from "@api/Customers/GetOrdersDepartmentsFilterRequest";

export class RentalOrdersFilter {
  @observable dateFrom: moment.Moment | null = null;
  @observable dateTo: moment.Moment | null = null;
  @observable agents: ApiBound<CustomerAgentDto[]> = "Loading";
  @observable agent?: CustomerAgentDto;
  @observable agentDepartments: ApiBound<CustomerDepartmentDto[]> = "Loading";
  @observable department?: CustomerDepartmentDto;
  @observable keyword: string = "";

  constructor() {
    bindToApi(property(this, "agentDepartments"), () => getOrdersDepartmentsFilter({}));
    bindToApi(property(this, "agents"), () => getOrdersAgentsFilter({}));
  }

  @action.bound
  clear() {
    this.dateFrom = null;
    this.dateTo = null;
    this.agent = undefined;
    this.department = undefined;
    this.keyword = "";
  }

  @computed get canFilterDepartments(): boolean {
    return isLoaded(this.agentDepartments) && this.agentDepartments.length > 1;
  }

  @computed get canFilterAgents(): boolean {
    return isLoaded(this.agents) && this.agents.length > 1;
  }

  public async activate() {
    await ensureLoaded(() => this.agentDepartments);
    await ensureLoaded(() => this.agents);
  }
}

export class RentalOrdersPage extends Page {
  readonly rentalOrderFilter: RentalOrdersFilter = new RentalOrdersFilter();
  readonly sorting: SortOption = { field: "SubmissionDate", sortDirection: SortDirection.descending };
  readonly inProcessRentalOrderStatuses = [RentalOrderStatus.new, RentalOrderStatus.confirmed];
  readonly fulfilledRentalOrderStatuses = [RentalOrderStatus.fulfilled];
  readonly completedRentalOrderStatuses = [RentalOrderStatus.completed];

  get selectedOrderId() {
    if (!this.childPage) return undefined;
    if (this.childPage instanceof RentalOrderDetailsPage) return this.childPage.orderId;
    return undefined;
  }

  inProcessItems = new PaginatedList(options => {
    return this.api.getItems({
      options: { sorting: this.sorting, ...options },
      filter: {
        rentalOrderStatuses: this.inProcessRentalOrderStatuses
      }
    });
  });

  activeItems = new PaginatedList(options => {
    return this.api.getItems({
      options: { sorting: this.sorting, ...options },
      filter: {
        rentalOrderStatuses: this.fulfilledRentalOrderStatuses,
        dateFrom: this.rentalOrderFilter.dateFrom?.startOf("day"),
        dateTo: this.rentalOrderFilter.dateTo?.endOf("day"),
        department: this.rentalOrderFilter.department?.id,
        agent: this.rentalOrderFilter.agent?.id,
        keyword: this.rentalOrderFilter.keyword
      }
    });
  });

  completedItems = new PaginatedList(options => {
    return this.api.getItems({
      options: { sorting: this.sorting, ...options },
      filter: {
        rentalOrderStatuses: this.completedRentalOrderStatuses,
        dateFrom: this.rentalOrderFilter.dateFrom?.startOf("day"),
        dateTo: this.rentalOrderFilter.dateTo?.endOf("day"),
        department: this.rentalOrderFilter.department?.id,
        agent: this.rentalOrderFilter.agent?.id,
        keyword: this.rentalOrderFilter.keyword
      }
    });
  });

  allItems = new PaginatedList(options => {
    return this.api.getItems({
      options: { sorting: this.sorting, ...options },
      filter: {
        dateFrom: this.rentalOrderFilter.dateFrom?.startOf("day"),
        dateTo: this.rentalOrderFilter.dateTo?.endOf("day"),
        department: this.rentalOrderFilter.department?.id,
        agent: this.rentalOrderFilter.agent?.id,
        keyword: this.rentalOrderFilter.keyword
      }
    });
  });

  readonly api = {
    getItems: catchErrors(getCustomerAgentRentalOrders)
  };

  constructor(public showAllOrders: Command, orderId?: number) {
    super();

    if (orderId) {
      this.showOrderDetails(orderId);
    }
  }

  async showOrderDetails(orderId: number) {
    const detailsPage = new RentalOrderDetailsPage(orderId, this.showAllOrders.execute, this.inProcessRentalOrderStatuses);
    await this.showChildPage(detailsPage);
    return detailsPage;
  }

  protected async onActivated() {
    await this.rentalOrderFilter.activate();
  }
}
