import {observer} from "mobx-react";
import * as React from "react";
import {IAlertDialog} from "./index";
import {Modal} from "antd";

@observer
export class AntAlert extends React.Component<{ dialog: IAlertDialog }> {

  componentDidMount() {
    const {title, content, isSuccess} = this.props.dialog;

    const modalProps = {
      title: title,
      content: (
        <div>
          <p>{content}</p>
        </div>
      ),
      onOk: () => this.props.dialog.ok()
    };

    isSuccess
      ? Modal.info(modalProps)
      : Modal.error(modalProps)
  }

  render() {
    return null;
  }
}