import * as React from "react";
import {observer} from "mobx-react";
import * as styles from "./CustomInputNumber.less";
import {ProductRentalRow} from "./CreateOrderStep2Page";
import {PlusOutlined, MinusOutlined} from '@ant-design/icons'
import {bindToContextualCommand, ContextualCommand } from "@mvvm/commands";
import { NumberInput } from "@components";

export const CustomInputNumber = observer(
  ({product: productRow, increase, decrease, onChange}:
     { product: ProductRentalRow, increase: ContextualCommand<ProductRentalRow>, decrease: ContextualCommand<ProductRentalRow>, onChange: (value: number | undefined) => void }) => (
    <div className={styles.CustomInputNumber}>
      <MinusOutlined className={styles.Icon} {...bindToContextualCommand(decrease, productRow)}/>
      <NumberInput value={productRow.quantity} onChange={onChange} />
      <PlusOutlined className={styles.Icon} {...bindToContextualCommand(increase, productRow)}/>
    </div>
  )
);