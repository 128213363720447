import { authentication } from "../auth/Authentication";
import { ApiErrorResponse } from "./Api/Model/ApiErrorResponse";
import { isApiErrorResponse, getFileNameFromContentDispositionHeader, loggerFactory, enableLogger } from "../Utils";
import modalService from "../infrastructure/modalService";
import { Form } from "antd";

const writeLog = loggerFactory("API Client");
enableLogger("API Client");

export const send = async <TResponse>(requestName: string, request: object, skipAccessToken: boolean) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };

  if (!skipAccessToken) {
    writeLog("send -> Getting access token", requestName);
    const accessToken = await authentication.getAccessToken();
    writeLog("send -> Got access token");
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
      headers["X-Requested-With"] = "XMLHttpRequest";
    }
  }

  const response: Response = await fetch(`/new-api/${requestName}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers
  });

  if (response.status === 204) {
    return {} as any;
  }

  let responseResult: ApiErrorResponse | TResponse = await response.json();

  switch (response.status) {
    case 409:
      responseResult = responseResult as ApiErrorResponse;
      responseResult.errors.push("This entry has been modified by another user. All fields have been refreshed.");
      return responseResult;
    case 400:
      responseResult = responseResult as ApiErrorResponse;
      return responseResult;
    case 500:
      responseResult = responseResult as ApiErrorResponse;
      handleErrorResponse(responseResult);
    default:
      return responseResult as TResponse;
  }
};

export const upload = async <TResponse>(requestName: string, request: any) => {
  const accessToken = await authentication.getAccessToken();

  function addObject(obj: any, formData: FormData, fieldNamePrefix = "") {
    for (const name of Object.keys(obj)) {
      if (Array.isArray(obj[name])) {
        for (const [i, item] of obj[name].entries()) {
          if (item instanceof File) {
            formData.append(fieldNamePrefix + name, item);
          }
          if (item !== Object(item)) {
            formData.append(fieldNamePrefix + name + `[${i}]`, item);
          } else {
            addObject(item, formData, fieldNamePrefix + name + `[${i}].`);
          }
        }
      } else {
        if (obj[name] instanceof File || obj[name] !== Object(obj[name])) {
          formData.append(fieldNamePrefix + name, obj[name]);
        } else {
          addObject(obj[name], formData, fieldNamePrefix + name + ".");
        }
      }
    }
  }

  const formData = new FormData();
  addObject(request, formData);

  const req: any = {
    method: "POST",
    body: formData
  };
  if (accessToken) {
    req.headers = {
      Authorization: `Bearer ${accessToken}`
    };
  }

  const response: Response = await fetch(`/api/${requestName}`, req);

  let responseResult: ApiErrorResponse | TResponse = await response.json();

  if (isApiErrorResponse(responseResult) && response.status === 500) {
    handleErrorResponse(responseResult);
  }
  return responseResult;
};

export const download = async (requestName: string, request: object, accept: string) => {
  const accessToken = await authentication.getAccessToken();

  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  const response: Response = await fetch(`/api/${requestName}`, {
    method: "POST",
    body: JSON.stringify(request),
    headers
  });

  return await response.blob();
  // return {
  //   file: await response.blob(),
  //   fileName: getFileNameFromContentDispositionHeader(response.headers.get("content-disposition"))
  // };
};

function handleErrorResponse(responseResult: ApiErrorResponse) {
  if (!responseResult.errors) {
    responseResult.errors = [];
  }
  if (responseResult.errors.length === 0) {
    responseResult.errors.push("Internal server error");
  }
  const error = responseResult.errors.join(" ");
  modalService.showError(error);
  throw new Error(error);
}

export function throwOnApiError<TResponse>(req: Promise<TResponse | ApiErrorResponse>): Promise<TResponse> {
  function handle(res: TResponse | ApiErrorResponse): TResponse {
    if (isApiErrorResponse(res)) {
      handleErrorResponse(res);
    }
    return <TResponse>res;
  }

  return req.then(handle).catch((res: TResponse | ApiErrorResponse | Error) => {
    if (res instanceof Error) {
      throw res;
    }
    return handle(res);
  });
}
