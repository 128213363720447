import * as React from "react";
import {observer} from "mobx-react";
import {ForgotPasswordPage} from "./ForgotPasswordPage";
import {TextInput} from "@components";
import {bindToCommand} from "@mvvm/commands";
import {AntField, AntForm} from "../../../../components/Forms/AntForm";
import {SubmitButton} from "../../../../components/SubmitButton/SubmitButton";
import {Spacer} from "../../../../components/Spacer/Spacer";

export const ForgotPasswordPageView = observer(({model}: { model: ForgotPasswordPage }) => (
  <>
    {!model.submitted && <>
      <Spacer>
        <p>
          Forgot your password? We'll send a password recovery link to:
        </p>
      </Spacer>
      <AntForm form={model.form} onFinish={model.sendRequestFunc}>
        {fields => (
          <>
            <AntField field={fields.emailAddress}>
              {props => <TextInput {...props} placeholder="Email address"/>}
            </AntField>
            <SubmitButton text={"Send password request"}/>
            <p>Know your password? <a {...bindToCommand(model.goToLoginPage)}>Login</a></p>
          </>
        )}
      </AntForm>
    </>
    }

    {model.submitted && <h2>Check your e-mail inbox for password reset link</h2>}
  </>
));
