import { action, computed, isAction, observable } from "mobx";
import { ContextualCommand } from "./ContextualCommand";

export function asyncCommand<T>(execute: () => Promise<T>, enabled?: () => boolean) {
  return new Command<T>(execute, enabled);
}

export function command<T>(
  execute: () => T,
  enabled?: () => boolean,
  options: { dontWrapInAction: boolean } = { dontWrapInAction: false }
) {
  return new Command<T>(execute, enabled, options.dontWrapInAction);
}

export class Command<T = any> {
  @computed get isEnabled() {
    return (this._isEnabled ? this._isEnabled() : true) && !this._isRunning;
  }

  @computed get isRunning() {
    return this._isRunning;
  }

  @observable private _isRunning: boolean = false;
  private readonly _isEnabled: (() => boolean) | null;
  private readonly _execute: (() => Promise<T>) | (() => T);

  constructor(execute: (() => Promise<T>) | (() => T), enabled?: () => boolean, dontWrapInAction = false) {
    this._execute = isAction(execute) || dontWrapInAction ? execute : action(execute);
    this._isEnabled = enabled || null;
  }

  execute = async (): Promise<T> => {
    if (this.isEnabled == false) return Promise.reject("Command is not enabled");

    this._isRunning = true;
    try {
      return await Promise.resolve(this._execute());
    } finally {
      this._isRunning = false;
    }
  };

  asContextualCommand<TContext>() {
    return new ContextualCommand<TContext, void>(
      c => this.execute(),
      c => this.isEnabled
    );
  }
}
