import * as React from "react";
import {observer} from "mobx-react";
import {ResetPasswordPage} from "./ResetPasswordPage";
import {TextInput} from "@components";
import {bindToCommand} from "@mvvm/commands";
import {AntField, AntForm} from "../../../../components/Forms/AntForm";
import {SubmitButton} from "../../../../components/SubmitButton/SubmitButton";

export const ResetPasswordPageView = observer(({model}: { model: ResetPasswordPage }) => (
  <>{!model.submitted && (
    <AntForm form={model.form} onFinish={model.sendRequestFunc}>
      {fields => (
        <>
          <AntField field={fields.password}>
            {props => <TextInput {...props} type="password" placeholder="Password"/>}
          </AntField>
          <AntField field={fields.passwordToConfirm}>
            {props => <TextInput {...props} type="password" placeholder="Confirm password"/>}
          </AntField>
            <SubmitButton text={"Send password reset request"}/>
        </>
      )}
    </AntForm>)}
    {model.submitted && (
      <h2>
        You can now <a {...bindToCommand(model.goToLoginPage)}>Login</a> using your new password!
      </h2>
    )}
  </>
));
        