import * as React from "react";
import { observer } from "mobx-react";
import { CreateOrderSummaryPage } from "./CreateOrderSummaryPage";
import * as styles from "./CreateOrderSummaryPage.less";
import { Avatar, Col, Row } from "antd";
import { FormContainer } from "../../../../../components/FormContainer/FormContainer";

import { List } from "antd";
import { Sticker, StickerVariant } from "../../../../../components/Sticker/Sticker";
import { FlexView } from "../../../../../components/FlexView";
import { TrashCar } from "../../../../../components/Icons/Icons";
import { WasteReductionCard } from "@components";

export const CreateOrderSummaryPageView = observer(({ model }: { model: CreateOrderSummaryPage }) => {
  const { data: order, selectedProducts: productRows } = model.order;

  return (
    <div className={styles.CreateOrderSummaryPage}>
      <h2 className={styles.Title}>Summary</h2>
      <Row>
        <Col span={24} lg={16}>
          <FormContainer className={styles.Container}>
            <h3>Products</h3>
            <List
              itemLayout="horizontal"
              dataSource={productRows}
              renderItem={productRow => (
                <List.Item>
                  <div className={styles.ListItem} style={{ fontSize: "14px" }}>
                    {productRow.product.name}
                  </div>
                  <div>Quantity: {productRow.quantity}</div>
                </List.Item>
              )}
            />
            <Row style={{ marginTop: "50px" }}>
              <Col span={24} lg={12} xl={8}>
                <h3>Details</h3>
                <ul className={styles.SummaryDetailsList}>
                  <li>Entrepreneur: {order.info.entrepreneur}</li>
                  <li>Project name: {order.info.project}</li>
                  <li>Project ID: {order.info.projectId}</li>
                </ul>
              </Col>
              <Col span={24} lg={12} xl={8}>
                <h3>Delivery</h3>
                <ul className={styles.SummaryDetailsList}>
                  <li>Shipping address: {order.info.shippingAddress}</li>
                  <li>Delivery date: {model.fulfillmentDate ?? ""}</li>
                  {model.includeSpeedDelivery && <li>Express packaging</li>}
                </ul>
              </Col>
              <Col span={24} lg={12} xl={8}>
                <h3>Contact person</h3>
                <ul className={styles.SummaryDetailsList}>
                  <li>Name: {order.info.contactPerson?.name}</li>
                  <li>Phone: {order.info.contactPerson?.phoneNumber}</li>
                </ul>
              </Col>
            </Row>
          </FormContainer>
        </Col>
        <WasteReductionCard wasteSaved={model.totalWasteSaved} />
      </Row>
    </div>
  );
});

export const TrashSticker = () => (
  <Sticker colorVariant={StickerVariant.blue}>
    <FlexView column alignItems={"center"}>
      <p style={{ fontSize: "24px" }}>Be a part!</p>
      <Avatar size={80} style={{ background: "transparent", fontSize: "60px", marginBottom: "10px" }} icon={<TrashCar />} />
      <div style={{ maxWidth: "150px", textAlign: "center" }}>
        <p>
          Recycling 1 tonne
          <br /> of plastic saves
        </p>
        <h3 style={{ fontSize: "32px" }}>1,5 tonnes</h3>
        <p>of carbon</p>
      </div>
    </FlexView>
  </Sticker>
);
