import { Page } from "@mvvm/Page";
import { command, Command } from "@mvvm/commands";
import { TokenResult } from "../../../data/Infrastructure/Auth/TokenResult";
import { LoginPage } from "./Login/LoginPage";
import { ForgotPasswordPage } from "./ForgotPassword/ForgotPasswordPage";
import { ResetPasswordPage } from "./ResetPassword/ResetPasswordPage";
import { getPasswordPolicy } from "@api/Auth/GetPasswordPolicyRequest";
import { PasswordPolicy } from "@api/Auth/Models/PasswordPolicy";
import { isApiErrorResponse } from "../../../Utils";
import { observable } from "mobx";
import { ActivateAccountPage } from "./ActivateAccount/ActivateAccountPage";

export interface IFieldValidationResult {
  isValid: boolean;
  errors?: string[];
}

export class AuthPage extends Page {
  isAuthPage: boolean = true;
  goToLoginPage: Command;

  @observable passwordPolicy: PasswordPolicy | undefined = undefined;

  constructor(private onAuthenticated: (result: TokenResult) => Promise<void>) {
    super();

    this.goToLoginPage = command(this.navigateToLoginPage);
  }

  protected async onActivated() {
    await getPasswordPolicy({}).then(policy => {
      if (!isApiErrorResponse(policy)) {
        this.passwordPolicy = policy;
      }
    });
  }

  navigateToLoginPage = async () => {
    return this.showChildPage(new LoginPage(this.onAuthenticated, command(this.navigateToForgotPasswordPage)));
  };

  navigateToActivateAccountPage = async (userId: string, token: string) => {
    return this.showChildPage(new ActivateAccountPage(userId, token, this.goToLoginPage, this.validatePassword));
  };

  navigateToForgotPasswordPage = async () => {
    return this.showChildPage(new ForgotPasswordPage(this.goToLoginPage));
  };

  navigateToResetPasswordPage = async (emailAddress: string, token: string) => {
    return this.showChildPage(new ResetPasswordPage(emailAddress, token, this.goToLoginPage, this.validatePassword));
  };

  private validatePassword = (password: string): true | string[] => {
    const errors: string[] = [];

    if (!this.passwordPolicy) return !!password ? true : ["Password is required"];

    const policy = this.passwordPolicy;

    if (!password) {
      errors.push("Password is required");
    } else {
      if (password.length < policy.requiredLength) {
        errors.push(`Password should contain at least ${policy.requiredLength} characters`);
      }
      if (policy.requireDigit && !/\d/.test(password)) {
        errors.push("Password should contain at least one digit");
      }
      if (policy.requireUppercase && !/[A-Z]/.test(password)) {
        errors.push("Password should contain at least one big letter");
      }
      if (policy.requireLowercase && !/[a-z]/.test(password)) {
        errors.push("Password should contain at least one small letter");
      }
      if (policy.requireNonAlphanumeric && !/[^a-zA-Z0-9]+/.test(password)) {
        errors.push("Password should contain at least one symbol, e.g. @ # $ % ^ . *");
      }
      if (policy.requiredUniqueChars > 1) {
        const isValid = (pass: string) => {
          const obj: { [char: string]: true } = {};
          for (const c of pass.split("")) {
            obj[c] = true;
            if (Object.keys(obj).length >= policy.requiredUniqueChars) return true;
          }
          return false;
        };
        if (!isValid(password)) {
          errors.push(`Password should contain at least ${policy.requiredUniqueChars} distinct characters`);
        }
      }
    }

    return errors.length ? errors : true;
  };
}
