import * as React from "react";
import * as styles from "./MobileOrderButton.less";
import {PlusOutlined} from '@ant-design/icons';
import {Button} from "antd";
import classNames from "classnames";

interface IMobileOrderButton {
  createOrder: () => void,
  onOrderButtonClick: () => void,
  isSidebarOpen: boolean
}

export function MobileOrderButton({createOrder, onOrderButtonClick, isSidebarOpen}: IMobileOrderButton) {

  const onCreateOrder = () => {
    onOrderButtonClick();
    createOrder();
  };

  return (
    <div onClick={onCreateOrder}
         className={classNames(
           styles.MobileOrderButton,
           {[styles.SidebarOpen]: isSidebarOpen}
         )}>
      <Button type={'primary'}>
        <PlusOutlined/>
        Order
      </Button>
    </div>
  )
}