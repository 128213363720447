import React, { useState, useEffect } from "react";
import Icon from '@ant-design/icons';

interface ICustomIconProps {
  type: string;
  className?: string;
  onClick?: ()=>void;
  size?: number;
}

export const CustomIcon = ({ type, className, ...props }: ICustomIconProps) => {
  const [iconComponent, setIconComponent] = useState<React.ReactElement>();
  useEffect(() => {
    import(`../../assets/icons/${type}.svg`)
      .then(importedObject => {
        setIconComponent(<Icon className={className} component={importedObject.default} {...props} />);
      })
      .catch(() => {
        setIconComponent(<Icon className={className} type={type} {...props} />);
      });
  }, [type, className]);

  return <>{iconComponent}</>;
};
