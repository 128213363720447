import { bindableForm } from "@mvvm/forms";
import { required, phone } from "@mvvm/forms/validators";
import { FormStep } from "../../FormStep";
import { computed, observable } from "mobx";
import { Moment } from "moment";
import { RentalOrderFormInfo } from "../../CreateOrderPage";
import { getServiceList, GetServiceListRequest } from "@api/Services/GetServiceListRequest";
import { ApiBound, bindToApi, ensureLoaded, isLoaded, property } from "@mvvm/index";
import { ServiceListItemDto } from "@api/Services/Model/ServiceListItemDto";
import momentBusinessDays from "moment-business-days";
import { CustomerDepartmentDto } from "@api/Customers/Model/CustomerDepartmentDto";
import { getAgentManagedDepartments } from "@api/Customers/GetAgentManagedDepartmentsRequest";

export interface Step1Form {
  entrepreneur: string;
  project: string;
  projectId: string;
  shippingAddress: string;
  deliveryDate: Moment | null;
  contactPersonName: string;
  contactPersonPhone: string;
  invoiceReference: string;
  customerDepartmentId: number | null;
  notes: string;
}

export class CreateOrderStep1Page extends FormStep {
  @observable speedDelivery: ServiceListItemDto | undefined;
  @observable deliveryServices: ApiBound<ServiceListItemDto[]> = "Loading";
  @observable agentDepartments: ApiBound<CustomerDepartmentDto[]> = "Loading";
  @observable selectedAgentDepartment?: CustomerDepartmentDto;
  request: GetServiceListRequest = { filter: { isDeliveryService: true } };

  constructor(private order: RentalOrderFormInfo) {
    super();
    bindToApi(property(this, "deliveryServices"), () => getServiceList(this.request));
    bindToApi(property(this, "agentDepartments"), () => getAgentManagedDepartments({}));
  }

  protected async onActivated() {
    var services = await ensureLoaded(() => this.deliveryServices);
    var departments = await ensureLoaded(() => this.agentDepartments);
    if (departments.length == 1) {
      this.selectedAgentDepartment = departments[0];
      this.form.fields.customerDepartmentId.value = this.selectedAgentDepartment.id;
    }
    this.speedDelivery = services.find(x => x.isExpressDeliveryService);
  }

  step1Data: Step1Form = {
    entrepreneur: "",
    project: "",
    projectId: "",
    shippingAddress: "",
    deliveryDate: null,
    contactPersonName: "",
    contactPersonPhone: "",
    notes: "",
    invoiceReference: "",
    customerDepartmentId: null
  };

  getDisabledDateRange(current: Moment) {
    return current && current < momentBusinessDays().businessAdd(3, "day").endOf("day");
  }

  @computed get agentCanManageDepartments(): boolean {
    return isLoaded(this.agentDepartments) && this.agentDepartments.length > 1;
  }

  // todo: bind directly to this.order
  form = bindableForm<Step1Form>()
    .addField("entrepreneur")
    .addField("project")
    .addField("projectId")
    .addField("shippingAddress", { validator: required() })
    .addField("deliveryDate", { validator: required() })
    .addField("customerDepartmentId", { validator: required() })
    .addField("contactPersonName")
    .addField("notes")
    .addField("invoiceReference")
    .addField("contactPersonPhone", { validator: phone() })
    .bindTo(() => this.step1Data);

  @computed get includeSpeedDelivery(): boolean {
    const deliveryDate = this.form.fields["deliveryDate"].value;
    const today = momentBusinessDays();
    return deliveryDate ? deliveryDate <= today.businessAdd(4, "day") : false;
  }

  async validateAndSubmit(): Promise<boolean> {
    if (this.includeSpeedDelivery && !this.speedDelivery) return false;

    const res = await this.form.validateAndCommit();
    if (!res) return false;

    const {
      project,
      projectId,
      entrepreneur,
      deliveryDate,
      shippingAddress,
      contactPersonName,
      contactPersonPhone,
      notes,
      invoiceReference,
      customerDepartmentId
    } = this.step1Data;

    const newData: { [name: string]: any } = {
      project,
      projectId,
      entrepreneur,
      notes,
      invoiceReference,
      shippingAddress: shippingAddress,
      fulfillmentDate: deliveryDate,
      customerDepartmentId: customerDepartmentId
    };
    const obj: { [name: string]: any } = this.order.info;
    for (const key of Object.keys(newData)) {
      obj[key] = newData[key];
    }

    this.order.info.contactPerson.name = contactPersonName;
    this.order.info.contactPerson.phoneNumber = contactPersonPhone;

    return true;
  }
}
