import {RentalOrderStatus} from "../../../../Externals"

export interface CustomerAgentRentalOrderListItemDto {
    id: number
    submissionDate: any
    fulfillmentDate: any
    number: string
    entrepreneur: string
    project: string
    projectId: string
    customerDepartmentName: string
    productsQuantity: string
    returnedQuantity: number
    productsDisplay: string
    status: RentalOrderStatus
    statusDisplay: string
    wasteSaved: number
    cost: number
}
export const CustomerAgentRentalOrderListItemDtoMetadata: {[key in keyof Required<CustomerAgentRentalOrderListItemDto>]: CustomerAgentRentalOrderListItemDtoPropertyMetadata} = {
    id: {
        $name: "id",
        $type: "int",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    submissionDate: {
        $name: "submissionDate",
        $type: "System.DateTimeOffset",
        gridColumn: {
            header: "Submission Date",
            minWidth: 100,
            maxWidth: 120,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    fulfillmentDate: {
        $name: "fulfillmentDate",
        $type: "System.DateTimeOffset",
        gridColumn: {
            header: "Delivery Date",
            minWidth: 100,
            maxWidth: 120,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    number: {
        $name: "number",
        $type: "string",
        gridColumn: {
            header: "Order Number",
            minWidth: 50,
            maxWidth: 80,
            isSelectionInvoke: true,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    entrepreneur: {
        $name: "entrepreneur",
        $type: "string",
        gridColumn: {
            header: "Entrepreneur",
            minWidth: 100,
            maxWidth: 200,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    project: {
        $name: "project",
        $type: "string",
        gridColumn: {
            header: "Project",
            minWidth: 100,
            maxWidth: 200,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    projectId: {
        $name: "projectId",
        $type: "string",
        gridColumn: {
            header: "Project ID",
            minWidth: 100,
            maxWidth: 200,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    customerDepartmentName: {
        $name: "customerDepartmentName",
        $type: "string",
        gridColumn: {
            header: "Department",
            minWidth: 100,
            maxWidth: 200,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    productsQuantity: {
        $name: "productsQuantity",
        $type: "string",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: false,
            canSort: false,
        },
    },
    returnedQuantity: {
        $name: "returnedQuantity",
        $type: "int",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: false,
            canSort: false,
        },
    },
    productsDisplay: {
        $name: "productsDisplay",
        $type: "string",
        gridColumn: {
            header: "Products/Returned",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    status: {
        $name: "status",
        $type: "NorskGjenbruk.Model.RentalOrderStatus",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canSort: true,
            name: "StatusDisplay",
        },
    },
    statusDisplay: {
        $name: "statusDisplay",
        $type: "string",
        gridColumn: {
            header: "Status",
            minWidth: 50,
            maxWidth: 120,
        },
    },
    wasteSaved: {
        $name: "wasteSaved",
        $type: "double",
        gridColumn: {
            header: "Waste saved",
            minWidth: 30,
            maxWidth: 80,
        },
        sieve: {
            canFilter: false,
            canSort: true,
        },
    },
    cost: {
        $name: "cost",
        $type: "decimal",
        gridColumn: {
            header: "Cost so far",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
}
export interface CustomerAgentRentalOrderListItemDtoPropertyMetadata {
    $name: string
    $type: string
    gridColumn?: {
        hidden?: boolean;
        header?: string;
        minWidth?: number;
        maxWidth?: number;
        isSelectionInvoke?: boolean;
    },
    sieve?: {
        canFilter?: boolean;
        canSort?: boolean;
        name?: string;
    },
}
