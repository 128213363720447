import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface GetAvailableProductsRequest {
}
export const GetAvailableProductsRequestMetadata: {[key in keyof Required<GetAvailableProductsRequest>]: GetAvailableProductsRequestPropertyMetadata} = {
}
export interface GetAvailableProductsRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getAvailableProducts = (getAvailableProductsRequest: GetAvailableProductsRequest): Promise<any | ApiErrorResponse> => {
    return send("GetAvailableProducts", getAvailableProductsRequest, false)
}
