import { FormStep } from "../../FormStep";
import { RentalOrderWithProducts } from "../../CreateOrderPage";
import { computed } from "mobx";
import moment from "moment";

export class CreateOrderSummaryPage extends FormStep {
  @computed get includeSpeedDelivery(): boolean {
    const deliveryDate = this.order.data.info.fulfillmentDate;
    if (deliveryDate === undefined) {
      return false;
    }

    const today = moment();
    return deliveryDate ? deliveryDate <= today.add(4, "days") : false;
  }

  @computed get fulfillmentDate(): string | undefined {
    const deliveryDate = this.order.data.info.fulfillmentDate;
    if (deliveryDate === undefined) {
      return undefined
    }
    return moment(deliveryDate).format("DD.MM.YYYY");
  }

  @computed get totalWasteSaved() {
    return (
      this.order.selectedProducts.reduce((sum, current) => sum + current.product.wasteReduction * (current.quantity ?? 0), 0) ?? 0
    );
  }

  constructor(public order: RentalOrderWithProducts) {
    super();
  }
}
