import * as React from "react";
import { observer } from "mobx-react";
import * as styles from "./FiltersBar.less";
import { DatePicker, Space, Tooltip } from "antd";
import { Input } from "antd";
import classNames from "classnames";
import { bindTo, property } from "@mvvm/index";
import { IconClose } from "../../../components/Icons/Icons";
import { RentalOrdersFilter } from "./RentalOrdersPage";
import { Select } from "@components";

const { Search } = Input;

export const FiltersBar = observer(({ panelMode, filter }: { panelMode?: boolean; filter: RentalOrdersFilter }) => {
  return (
    <div className={styles.FiltersBar}>
      {(!!filter.dateFrom || !!filter.dateTo || !!filter.agent || !!filter.department || !!filter.keyword) && (
        <span className={classNames(styles.FiltersInfo, { [styles.FiltersInfoPanelMode]: panelMode })} onClick={filter.clear}>
          <IconClose className={styles.DiscardIcon} /> Clear filters
        </span>
      )}
      <Space direction="horizontal" className={classNames(styles.Container, { [styles.FiltersBarPanelMode]: panelMode })}>
        <div className={styles.FilterFieldContainer}>
          <DatePicker.RangePicker
            bordered={false}
            placeholder={["Delivered from", "Delivered to"]}
            value={[filter.dateFrom, filter.dateTo]}
            onChange={values => {
              filter.dateFrom = values?.[0] ?? null;
              filter.dateTo = values?.[1] ?? null;
            }}
          />

          {filter.canFilterDepartments && (
            <Select
              bordered={false}
              placeholder="Department"
              className={styles.FilterField}
              dataSource={filter.agentDepartments}
              value={filter.department}
              renderOptionContent={item => item.name}
              onChange={value => {
                filter.department = value;
              }}
            />
          )}
          
          {filter.canFilterDepartments && (
          <Select
            bordered={false}
            className={styles.FilterField}
            placeholder="Agent"
            dataSource={filter.agents}
            value={filter.agent}
            renderOptionContent={item => item.name}
            onChange={value => {
              filter.agent = value;
            }}
          />
        )}
        </div>

        <div>
          <Tooltip title="Search uses Project, ProjectID, Entrepreneur and Order Number columns">
            <Search
              className={classNames(styles.FiltersBarSearch, { [styles.FiltersBarSearchPanelMode]: panelMode })}
              placeholder="Search"
              value={filter.keyword}
              onChange={event => (filter.keyword = event.target.value)}
              onSearch={value => (filter.keyword = value)}
              allowClear
            />
          </Tooltip>
        </div>
      </Space>
    </div>
  );
});
