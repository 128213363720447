import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {ActivationInfoResult} from "./Model/ActivationInfoModel"

export interface GetActivationInfoRequest {
    userId: string
    confirmationToken: string
}
export const GetActivationInfoRequestMetadata: {[key in keyof Required<GetActivationInfoRequest>]: GetActivationInfoRequestPropertyMetadata} = {
    userId: {
        $name: "userId",
        $type: "string",
        required: true,
    },
    confirmationToken: {
        $name: "confirmationToken",
        $type: "string",
        required: true,
    },
}
export interface GetActivationInfoRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const getActivationInfo = (getActivationInfoRequest: GetActivationInfoRequest): Promise<ActivationInfoResult | ApiErrorResponse> => {
    return send("GetActivationInfo", getActivationInfoRequest, true)
}
