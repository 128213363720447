import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface GetAgentManagedDepartmentsRequest {
}
export const GetAgentManagedDepartmentsRequestMetadata: {[key in keyof Required<GetAgentManagedDepartmentsRequest>]: GetAgentManagedDepartmentsRequestPropertyMetadata} = {
}
export interface GetAgentManagedDepartmentsRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getAgentManagedDepartments = (getAgentManagedDepartmentsRequest: GetAgentManagedDepartmentsRequest): Promise<any | ApiErrorResponse> => {
    return send("GetAgentManagedDepartments", getAgentManagedDepartmentsRequest, false)
}
