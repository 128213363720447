import * as React from "react";
import * as styles from "./UserPanelView.less";
import shc from "string-hash-colour";

interface IUserPanelViewProps {
    name: string;
    email: string;
    onLogout: () => void;
    onUserAccountClick: () => void;
}

export function UserPanelView(props: IUserPanelViewProps) {
    return (
        <div className={styles.UserPanel}>
            <div className={styles.UserPanelDetails}>
                <div
                    className={styles.UserPanelIcon}
                    style={{ backgroundColor: shc.convert(props.name, { avoid: "#ffffff", proximity: 100 }) }}>
                    {props.name.charAt(0)}
                </div>

                <div className={styles.UserPanelText}>
                    <p className={styles.UserPanelTextLine}>{props.name}</p>
                    <p className={styles.UserPanelTextLine}>{props.email}</p>
                    {/*<a onClick={props.onUserAccountClick} className={styles.UserPanelMyAccount}>My Account</a>*/}
                    <a onClick={props.onLogout} className={styles.UserPanelSignOut}>Sign Out</a>
                </div>
            </div>
        </div>
    )
}