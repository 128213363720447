import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {UserDto} from "./Model/UserDto"

export interface GetCurrentUserRequest {
}
export const GetCurrentUserRequestMetadata: {[key in keyof Required<GetCurrentUserRequest>]: GetCurrentUserRequestPropertyMetadata} = {
}
export interface GetCurrentUserRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getCurrentUser = (getCurrentUserRequest: GetCurrentUserRequest): Promise<UserDto | ApiErrorResponse> => {
    return send("GetCurrentUser", getCurrentUserRequest, false)
}
