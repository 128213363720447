import {action, computed, observable} from "mobx";
import { IAlertDialog, IConfirmationDialog } from "@mvvm/dialogs";
import { IPage } from "@mvvm/routing";
import { IMessage } from "@mvvm/IMessage";

export abstract class Page<TDialog = never> implements IPage {
  @observable dialog: TDialog | IConfirmationDialog | IAlertDialog | undefined;
  @observable readonly messages: IMessage[] = [];

  @computed get childPage(): IPage | undefined {
    return this._childPage;
  }

  @observable private _childPage: IPage | undefined = undefined;

  private isActive: boolean = false;

  async activate() {
    this.isActive = true;
    if (this._childPage) await this._childPage.activate();
    await this.onActivated();
  }

  deactivate() {
    if (this._childPage) this._childPage.deactivate();
    this.isActive = false;
    this.onDeactivated();
  }

  removeChildPage = () => {
    if (this._childPage && this.isActive) this._childPage.deactivate();
    this._childPage = undefined;
  };

  async showChildPage<T extends IPage>(newPage: T) {
    if (this._childPage && this.isActive) this._childPage.deactivate();
    this._childPage = newPage;
    if (this._childPage && this.isActive) await this._childPage.activate();
    return newPage;
  }

  async showModal<TResult>(
    createDialog: (close: (result: TResult) => void) => TDialog | IConfirmationDialog | IAlertDialog
  ): Promise<TResult> {
    return new Promise(r => {
      this.dialog = createDialog(result => {
        this.dialog = undefined;
        r(result);
      });
    });
  }

  protected onActivated() {}

  protected onDeactivated() {}
}
