import * as React from "react";
import { observer } from "mobx-react";
import { CreateOrderStep2Page, ProductRentalRow } from "./CreateOrderStep2Page";
import { isLoaded } from "@mvvm/index";
import { Alert, Avatar, Col, Row, Spin, Table, Tooltip } from "antd";
import { isApiErrorResponse, toNorwegianNumberFormat } from "../../../../../Utils";
import { FormContainer } from "../../../../../components/FormContainer/FormContainer";
import * as styles from "./CreateOrderStep2Page.less";
import classNames from "classnames";
import { Sticker, StickerVariant } from "../../../../../components/Sticker/Sticker";
import { FlexView } from "../../../../../components/FlexView";
import { CustomInputNumber } from "./CustomInputNumber";
import { TrashCar } from "../../../../../components/Icons/Icons";
import { TrashSticker } from "../Summary/CreateOrderSummaryPageView";
import { CustomIcon, WasteReductionCard } from "@components";
import { InfoCircleOutlined } from "@ant-design/icons";

const getColumns = (model: CreateOrderStep2Page) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_: any, row: ProductRentalRow, index: number) => (
      <>
        <span className={styles.ProductName}>{row.product.name}</span>
        {!!row.product.description && (
          <Tooltip title={row.product.description}>
            <InfoCircleOutlined style={{color: "#23A56B"}}/>
          </Tooltip>
        )}
      </>
    )
  },
  {
    title: "Waste [kg]",
    dataIndex: "wasteSaved",
    key: "wasteSaved",
    align: "center" as "center",
    render: (_: any, row: ProductRentalRow) => <span>{toNorwegianNumberFormat(row.product.wasteReduction * (row.quantity ?? 0), 1)}</span>
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    align: "center" as "center",
    render: (_: any, row: ProductRentalRow) => (
      <CustomInputNumber
        key={row.product.id}
        increase={model.increaseAmount}
        decrease={model.decreaseAmount}
        product={row}
        onChange={value => {
          model.overrideLineQuantity(row, value);
        }}
      />
    )
  }
];

export const CreateOrderStep2PageView = observer(({ model }: { model: CreateOrderStep2Page }) => (
  <div className={styles.CreateOrderStep2Page}>
    <h2 className={styles.Title}>Products</h2>
    <Row>
      <Col span={24} lg={16}>
        <FormContainer className={classNames(styles.Container, { [styles.ListLoader]: !isLoaded(model.rentalLines) })}>
          {!isLoaded(model.rentalLines) && <Spin />}
          {isApiErrorResponse(model.rentalLines) && <Alert type={"error"} message={"There was an error while getting product list"} />}
          {isLoaded(model.rentalLines) && (
            <Table
              rowKey={x => x.product.id}
              rowClassName={(record: ProductRentalRow, _) =>
                record.quantity !== undefined && record.quantity > 0 ? "ant-table-row-selected" : ""
              }
              dataSource={model.rentalLines}
              columns={getColumns(model)}
              footer={() => <TableFooter data={model.rentalLines as ProductRentalRow[]} />}
              pagination={false}
            />
          )}
        </FormContainer>
      </Col>
      <WasteReductionCard wasteSaved={model.totalWasteSaved} />
    </Row>
  </div>
));

const TableFooter = observer(({ data }: { data: ProductRentalRow[] }) => (
  <FlexView>
    <span>Total products: {data.reduce((sum, current) => sum + (current.quantity ?? 0), 0)}</span>
  </FlexView>
));
