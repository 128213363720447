import * as React from "react";
import {observer} from "mobx-react";
import {CreateOrderPage} from "./CreateOrderPage";
import {ContentView} from "@mvvm/ContentView";
import * as styles from './CreateOrderPage.less';
import {FlexView} from "../../../components/FlexView";
import {StepsFooter} from "./Steps/StepsFooter/StepsFooter";
import {Submitted} from "./Steps/Submitted/Submitted";

export const CreateOrderPageView = observer(({model}: { model: CreateOrderPage }) => (
  <FlexView column className={styles.CreateOrderPageView}>
    {model.submitted && <Submitted createNewOrder={model.dismiss} showAllOrders={model.showAllOrders}/>}
    {!model.submitted && <>
      <ContentView content={model.childPage}/>
      <StepsFooter model={model}/>
    </>}
  </FlexView>
));