import * as React from "react";
import {Button} from "antd";
import * as styles from "./SubmitButton.less";

interface ISubmitButton {
  text: string;
  icon?: React.ReactNode;
}

export const SubmitButton = ({text, icon, ...rest}: ISubmitButton) => {
  
  return (
    <Button
      type="primary"
      htmlType="submit"
      className={styles.SubmitButton}
      {...rest}
    >
      {text} {icon}
    </Button>
  )
};