import * as React from "react";
import * as styles from './Submitted.less';
import {Avatar} from 'antd';
import {OrderPlacedBadge} from "../../../../../components/Icons/Icons";
import {SubmitButton} from "../../../../../components/SubmitButton/SubmitButton";
import {Typography} from 'antd';
import {Spacer} from "../../../../../components/Spacer/Spacer";
import {bindToCommand, Command} from "@mvvm/commands";

const {Link} = Typography;

export const Submitted = ({createNewOrder, showAllOrders}: {createNewOrder: Command, showAllOrders: Command}) => (
  <div className={styles.Submitted}>
    <div className={styles.Container}>
      <Avatar size={120} icon={<OrderPlacedBadge/>}/>
      <Spacer>
        <ul className={styles.OrderProgress}>
          <li>Received</li>
          <li>In progress</li>
          <li>Shipped</li>
        </ul>
      </Spacer>
      <h1>Your order has been created!</h1>
      <Spacer>
        <SubmitButton {...bindToCommand(createNewOrder)} text={"Create New Order"}/>
      </Spacer>
      <Link {...bindToCommand(showAllOrders)}>
        See all orders
      </Link>
    </div>
  </div>
);