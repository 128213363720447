import * as React from "react";
import { observer } from "mobx-react";
import { RentalOrderDetailsPage } from "./RentalOrderDetailsPage";
import * as styles from "./RentalOrderDetailsPage.less";
import { Button, Col, Row, Space, Spin, Table, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { isLoaded } from "@mvvm/index";
import { buildColumnsDefinition, columnConverter } from "../../../GridUtils";
import { CustomerAgentRentalOrderProductLineDtoMetadata } from "@api/Orders/RentalOrders/Model/CustomerAgentRentalOrderProductLineDto";
import { StatusLabel } from "../../../components/StatusLabel/StatusLabel";
import { FlexView } from "../../../components/FlexView";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CustomerAgentRentalOrderRentalLineDtoMetadata } from "@api/Rentals/Model/CustomerAgentRentalOrderRentalLineDto";
import { toNorwegianDateFormat } from "../../../Utils";
import { bindToCommand } from "@mvvm/commands";
import classNames from "classnames";
import { CustomerAgentRentalOrderServiceLineDtoMetadata } from "@api/Orders/RentalOrders/Model/CustomerAgentRentalOrderServiceLineDto";
import { WasteReductionCard } from "../../../components/WasteReductionCard/WasteReductionCard";

const { Link } = Typography;

export const RentalOrderDetailsPageView = observer(
  ({
    model: { order, close, inProcessRentalOrderStatuses: inProcessRentalOrderStatuses, deleteOrder, showDeleteBtn }
  }: {
    model: RentalOrderDetailsPage;
  }) => {
    const productColumns = buildColumnsDefinition(CustomerAgentRentalOrderProductLineDtoMetadata, columnConverter, {
      productName: {
        fixed: "left"
      }
    });

    const serviceColumns = buildColumnsDefinition(CustomerAgentRentalOrderServiceLineDtoMetadata, columnConverter, {
      serviceName: {
        fixed: "left"
      }
    });

    const rentalColumns = buildColumnsDefinition(CustomerAgentRentalOrderRentalLineDtoMetadata, columnConverter, {
      orderedProductName: {
        fixed: "left"
      },
      endDate: {
        render: (dateString: string) => <>{toNorwegianDateFormat(dateString)}</>
      },
      statusDisplay: {
        render: (statusDisplay: string, item: any) => <StatusLabel statusDisplay={statusDisplay} status={item.status} />
      },
      duration: {
        align: "center"
      }
    });

    return (
      <div className={styles.RentalOrderDetailsPage}>
        {isLoaded(order) && (
          <>
            <FlexView className={styles.HeaderContainer} alignItems="center">
              <Space size="large">
                <Link onClick={close} className={styles.LinkBackToList}>
                  <ArrowLeftOutlined />
                </Link>
              </Space>
              <Space size={"large"}>
                <h2 className={styles.Header}>Order - {order.number}</h2>
                <StatusLabel statusDisplay={order.statusDisplay} status={order.status} className={order.statusDisplay} />
              </Space>
            </FlexView>
            {showDeleteBtn && (
              <div className={styles.Options}>
                <Button
                  type="primary"
                  shape="round"
                  className={classNames(styles.DeleteOrder, { [styles.GrayedOut]: !deleteOrder.isEnabled })}
                  icon={<DeleteFilled />}
                  {...bindToCommand(deleteOrder)}
                >
                  Cancel
                </Button>
              </div>
            )}
            <div className={classNames(styles.Content, { [styles.noTopPadding]: showDeleteBtn })}>
              <Space direction="vertical" size="large" className={styles.Content__inner}>
                <Row justify="space-between">
                  <Col
                    className={styles.OrderDetails}
                    span={24}
                    order={1}
                    sm={{ span: 11, order: 1 }}
                    md={{ span: 11, order: 1 }}
                    lg={{ span: 7, order: 3 }}
                  >
                    <h3>Details</h3>
                    <ul className={styles.DetailsList}>
                      <li>
                        Buyer: <strong>{order.buyer}</strong>
                      </li>
                      <li>
                        Department: <strong>{order.customerDepartmentName}</strong>
                      </li>
                      <li>
                        Name: <strong>{order.customerName}</strong>
                      </li>
                      <li>
                        Entrepreneur: <strong>{order.entrepreneur}</strong>
                      </li>
                      <li>
                        Project: <strong>{order.project}</strong>
                      </li>
                      <li>
                        Project ID: <strong>{order.projectId}</strong>
                      </li>
                      <li>
                        Submission date: <strong>{toNorwegianDateFormat(order.submissionDate)}</strong>
                      </li>
                      {order.invoiceReference && (
                        <li>
                          Invoice reference: <strong>{order.invoiceReference}</strong>
                        </li>
                      )}
                      {order.notes && (
                        <li>
                          Notes: <strong>{order.notes}</strong>
                        </li>
                      )}
                    </ul>
                  </Col>
                  <Col
                    className={styles.OrderDetails}
                    span={24}
                    order={2}
                    sm={{ span: 24, order: 3 }}
                    md={{ span: 11, order: 3 }}
                    lg={{ span: 7, order: 3 }}
                  >
                    <h3>Delivery</h3>
                    <ul className={styles.DetailsList}>
                      <li>
                        Warehouse: <strong>{order.fulfillmentWarehouseName}</strong>
                      </li>
                      <li>
                        Shipping address: <strong>{order.shippingAddress}</strong>
                      </li>
                      <li>
                        Delivery date: <strong>{toNorwegianDateFormat(order.fulfillmentDate)}</strong>
                      </li>
                    </ul>
                    <h3>Contact person</h3>
                    {order.contactPerson ? (
                      <ul className={styles.DetailsList}>
                        <li>{order.contactPerson?.name}</li>
                        <li>
                          Phone: <strong>{order.contactPerson?.phoneNumber}</strong>
                        </li>
                      </ul>
                    ) : (
                      "-"
                    )}
                  </Col>
                  <WasteReductionCard wasteSaved={order.wasteSaved} />
                </Row>
                <div>
                  <h3>Products</h3>
                  <Table
                    rowKey={x => x.id}
                    dataSource={order.products}
                    columns={productColumns}
                    pagination={false}
                    className={styles.ProductsList}
                  />
                </div>
                <div>
                  <h3>Services</h3>
                  <Table
                    rowKey={x => x.id}
                    dataSource={order.services}
                    columns={serviceColumns}
                    pagination={false}
                    className={styles.ProductsList}
                  />
                </div>
                {!inProcessRentalOrderStatuses.includes(order.status) && (
                  <div>
                    <h3>Inventory</h3>
                    <Table
                      rowKey={x => x.id}
                      dataSource={order.rentals}
                      columns={rentalColumns}
                      pagination={false}
                      className={styles.ProductsList}
                    />
                  </div>
                )}
              </Space>
            </div>
          </>
        )}
        {!isLoaded(order) && (
          <div className={styles.Spinner}>
            <Spin />
          </div>
        )}
      </div>
    );
  }
);
