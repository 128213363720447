import * as React from "react";
import * as styles from "./FormContainer.less";
import classNames from "classnames";

interface IFormContainer extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const FormContainer = (props: IFormContainer) => {
  return (
    <div className={classNames(styles.FormContainer, props.className)} {...props}>
      {props.children}
    </div>
  );
};
