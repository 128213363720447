import * as React from "react";
import {ApiBound, ensureLoaded, isLoaded} from "@mvvm/index";
import {Select as AntSelect} from "antd";
import {SelectProps} from "antd/lib/select";
import {ReactNode} from "react";
import {ContextualCommand} from "@mvvm/commands";

interface ISelectProps<T> {
  dataSource: ApiBound<T[]> | T[];
  value: T | undefined;
  onChange?: (value: T | undefined) => void;
  renderOptionContent: (item: T) => ReactNode;
  optionClassName?: string;
}

export function Select<T extends { id: string | number }>(
  props: ISelectProps<T> & Omit<SelectProps<string | number>, "dataSource" | "value" | "onChange" | "mode">
) {
  const {dataSource, value, onChange, renderOptionContent, optionClassName, ...rest} = props;
  return (
    <AntSelect
      {...rest}
      value={value && value.id}
      loading={!isLoaded(dataSource)}
      onChange={async (value: string | number) =>
        value ? onChange && onChange((await ensureLoaded(() => dataSource)).find(i => i.id === value)) : undefined
      }
    >
      {isLoaded(dataSource) &&
      dataSource.map(i => (
        <AntSelect.Option key={i.id} value={i.id} className={optionClassName}>
          {renderOptionContent(i)}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );
}

interface IMultiSelectProps<T> {
  dataSource: ApiBound<T[]> | T[];
  value: T[];
  onChange?: (value: T[]) => void;
  renderOptionContent: (item: T) => ReactNode;
  optionClassName?: string;
  onSelect?: ContextualCommand<T>;
  onDeselect?: ContextualCommand<T>;
}

export function MultiSelect<T extends { id: string | number }>(
  props: IMultiSelectProps<T> &
    Omit<SelectProps<(string | number)[]>, "dataSource" | "value" | "onChange" | "mode" | "onSelect" | "onDeselect">
) {
  const {dataSource, value, onChange, renderOptionContent, optionClassName, onSelect, onDeselect} = props;

  return (
    <AntSelect
      {...props}
      value={value.map(i => i.id)}
      mode="multiple"
      loading={!isLoaded(dataSource)}
      onChange={async (value: (string | number)[]) =>
        onChange && onChange((await ensureLoaded(() => dataSource)).filter(i => value.find(id => id === i.id)))
      }
      onSelect={select}
      onDeselect={deselect}
    >
      {isLoaded(dataSource) &&
      dataSource.map(i => (
        <AntSelect.Option key={i.id} value={i.id} className={optionClassName}>
          {renderOptionContent(i)}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );

  async function select(id: string | number) {
    if (!onSelect) return;

    const item = (await ensureLoaded(() => dataSource)).find(i => i.id === id);
    if (!item) return;

    if (!onSelect.canExecute(item)) return;

    return onSelect.execute(item);
  }

  async function deselect(id: string | number) {
    if (!onDeselect) return;

    const item = (await ensureLoaded(() => dataSource)).find(i => i.id === id);
    if (!item) return;

    if (!onDeselect.canExecute(item)) return;

    return onDeselect.execute(item);
  }
}
