import * as React from "react";
import * as styles from "./Logo.less";
import classNames from "classnames";

interface ILogo {
  src: string
  variant?: LogoVariant
  isSidebarOpen?: boolean
}

export enum LogoVariant {
  noText
}

export const Logo = (props: ILogo) => {

  return (
    <div className={classNames(
      styles.Container,
      {[styles.NoText]: props.variant === LogoVariant.noText},
      {[styles.SidebarOpen]: props.isSidebarOpen}
    )}>
      <a href='/'>
        <img
          className={styles.Image}
          src={props.src}
          alt=''
        />
      </a>
    </div>
  );
};