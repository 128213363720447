import React from 'react';
import {observer} from "mobx-react";
import {ContentView} from "@mvvm/ContentView";
import {ApplicationShell} from "./ApplicationShell";
import {Layout} from 'antd';
import * as styles from "./SidebarLayout.less";
import {useState} from "react";
import {SidebarView} from "../../Sidebar/SidebarView";
import {HeaderView} from "../../Header/HeaderView";
import {IconClose, IconMenu} from "../../../components/Icons/Icons";

const {Content, Footer} = Layout;

export const SidebarLayout = observer(({model}: { model: ApplicationShell }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const onMobileMenuOpen = () => {
    setSidebarVisible(true);
    document.body.classList.add('OverflowHidden');
  };
  
  const onMobileMenuClose = () => {
    document.body.classList.remove('OverflowHidden');
    setSidebarVisible(false)
  };

  const MobileNavigation = () => sidebarVisible
    ? <IconClose className={styles.Close} onClick={onMobileMenuClose}/>
    : <IconMenu className={styles.Hamburger} onClick={onMobileMenuOpen}/>;

  return (
    <Layout className={styles.SidebarLayout}>
      <SidebarView
        createOrder={model.navigateToCreateOrderPage}
        isSidebarVisible={sidebarVisible}
        onMenuItemClick={onMobileMenuClose}
        navigateToOrdersPage={model.navigateToRentalOrdersPage}
      />
      <div className={styles.Dashboard}>
        <MobileNavigation/>
        <HeaderView
          createOrder={model.navigateToCreateOrderPage}
          user={model.user}
          isSidebarVisible={sidebarVisible}
          onButtonClick={onMobileMenuClose}
        />
        <Content>
          <ContentView content={model.childPage}/>
        </Content>
      </div>
    </Layout>
  )
});


