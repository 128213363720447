import { send } from "../../../api";
import { ApiErrorResponse } from "../../../Api/Model/ApiErrorResponse";
import {RentalOrderInfoDto} from "./Model/RentalOrderInfoDto"
import {RentalOrderProductLineDto} from "./Model/RentalOrderProductLineDto"

import {RentalOrderDto} from "./Model/RentalOrderDto"

export interface CreateCustomerRentalOrderRequest {
    rentalOrderInfo: RentalOrderInfoDto
    productLines: RentalOrderProductLineDto[]
}
export const CreateCustomerRentalOrderRequestMetadata: {[key in keyof Required<CreateCustomerRentalOrderRequest>]: CreateCustomerRentalOrderRequestPropertyMetadata} = {
    rentalOrderInfo: {
        $name: "rentalOrderInfo",
        $type: "NorskGjenbruk.Server.Api.Orders.RentalOrders.Model.RentalOrderInfoDto",
    },
    productLines: {
        $name: "productLines",
        $type: "System.Collections.Generic.IEnumerable<NorskGjenbruk.Server.Api.Orders.RentalOrders.Model.RentalOrderProductLineDto>",
    },
}
export interface CreateCustomerRentalOrderRequestPropertyMetadata {
    $name: string
    $type: string
}
export const createCustomerRentalOrder = (createCustomerRentalOrderRequest: CreateCustomerRentalOrderRequest): Promise<RentalOrderDto | ApiErrorResponse> => {
    return send("CreateCustomerRentalOrder", createCustomerRentalOrderRequest, false)
}
