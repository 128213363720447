import * as React from "react";
import { observer } from "mobx-react";
import { CreateOrderStep1Page, Step1Form } from "./CreateOrderStep1Page";
import { AntField, AntForm } from "../../../../../components/Forms/AntForm";
import { TextArea, TextInput, Select } from "@components";
import { Alert, Col, DatePicker, Row } from "antd";
import { FormFields } from "@mvvm/forms";
import * as styles from "./CreateOrderStep1Page.less";
import { FormContainer } from "../../../../../components/FormContainer/FormContainer";
import { DetailsTab as DetailsTab } from "./DetailsTab";
import { toNorwegianNumberFormat } from "../../../../../Utils";

export const CreateOrderStep1PageView = observer(({ model }: { model: CreateOrderStep1Page }) => (
  <div className={styles.CreateOrderStep1Page}>
    <h2 className={styles.Title}>Details and delivery</h2>
    <Row>
      <Col span={24} xl={18}>
        <FormContainer>
          <AntForm form={model.form} style={{ maxWidth: "1400px" }}>
            {fields => (
              <>
                <Row>
                  <h2 className={styles.SubTitle}>Invoice reference</h2>
                </Row>
                <Row>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.customerDepartmentId} label="Department" className={styles.Required}>
                        {props => (
                          <Select
                            placeholder="Select department"
                            {...props}
                            dataSource={model.agentDepartments}
                            value={model.selectedAgentDepartment}
                            renderOptionContent={item => item.name}
                            disabled={!model.agentCanManageDepartments}
                            onChange={value => {
                              model.selectedAgentDepartment = value;
                              fields.customerDepartmentId.value = value?.id;
                            }}
                          />
                        )}
                      </AntField>
                    </div>
                  </Col>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.invoiceReference} label="Invoice reference">
                        {props => <TextInput {...props} placeholder="Add reference to external invoice" />}
                      </AntField>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.project} label="Project name">
                        {props => <TextInput {...props} />}
                      </AntField>
                    </div>
                  </Col>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.projectId} label="Project ID">
                        {props => <TextInput {...props} placeholder="" />}
                      </AntField>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <h2 className={styles.SubTitle}>Delivery and contact details</h2>
                </Row>
                <Row>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.shippingAddress} className={styles.Required} label="Address">
                        {props => <TextInput {...props} placeholder="Location of products to be delivered " />}
                      </AntField>
                    </div>
                  </Col>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.deliveryDate} label={"Date"} className={styles.Required}>
                        {props => <DatePicker {...props} placeholder={"Select delivery date"} disabledDate={model.getDisabledDateRange} />}
                      </AntField>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.entrepreneur} label="On-site delivery information">
                        {props => <TextInput {...props} placeholder="Name of the entrepreneur or other" />}
                      </AntField>
                    </div>
                  </Col>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      {model.includeSpeedDelivery && model.speedDelivery && (
                        <Alert
                          className={styles.Alert}
                          message={`Additional cost will be added for ${model.speedDelivery.name}`}
                          type="info"
                        />
                      )}
                      {model.includeSpeedDelivery && !model.speedDelivery && (
                        <Alert
                          className={styles.Alert}
                          message={"The express packaging service is not defined. Contact the administrator to process an order."}
                          type="error"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.contactPersonName} label="Contact person's name">
                        {props => <TextInput {...props} placeholder="Receiver's name" />}
                      </AntField>
                    </div>
                  </Col>
                  <Col span={24} xl={12}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.contactPersonPhone} label="Contact person's phone">
                        {props => <TextInput {...props} />}
                      </AntField>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} xl={24}>
                    <div className={styles.FieldGroup}>
                      <AntField field={fields.notes} label="Additional information">
                        {props => <TextInput {...props} />}
                      </AntField>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </AntForm>
        </FormContainer>
      </Col>
    </Row>
  </div>
));
