import * as React from "react";
import * as styles from "./Label.less";
import classNames from "classnames";

interface ILabelProps {
  text: string | React.ReactNode;
  className?: string;
  contentContainerClassName?: string;
  reverse?: boolean;
  children?: React.ReactNode;
}

export const Label = ({ text, className, contentContainerClassName, reverse, children }: ILabelProps) => {
  const contentContainerRef = React.useRef<HTMLDivElement>(null);

  const isSelectElementAndOpened = (element: HTMLElement) =>
    element.classList.contains("ant-select") && document.querySelector(".ant-select-dropdown:not(.ant-select-dropdown-hidden)");
  const clickElement = (element: HTMLElement) => {
    !isSelectElementAndOpened(element) && element.click();
  };

  const content = (
    <>
      {children && (
        <div className={classNames(styles.content, contentContainerClassName)} ref={contentContainerRef}>
          {children}
        </div>
      )}
    </>
  );

  return (
    <label className={classNames(styles.container, className)}>
      {reverse && content}
      <div
        className={styles.label}
        onClick={() =>
          Array.from(contentContainerRef.current?.children || []).forEach(
            element => element instanceof HTMLElement && clickElement(element)
          )
        }
      >
        {text}
      </div>
      {!reverse && content}
    </label>
  );
};
