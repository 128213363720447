import React from "react";
import {ApiBound} from "@mvvm/index";
import {UserDto} from "@api/Users/Model/UserDto";
import * as styles from "./HeaderView.less";
import {observer} from "mobx-react";
import {Logo, LogoVariant} from "../../components/Logo/Logo";
import {UserDetailsView} from "./UserDetails/UserDetailsView";
import {MobileOrderButton} from "./MobileOrderButton/MobileOrderButton";
import logoSrc from "./../../assets/looping-logo-no-text.svg"
import classNames from "classnames";

interface IHeaderViewProps {
  user: ApiBound<UserDto>;
  createOrder: () => void;
  isSidebarVisible: boolean;
  onButtonClick: () => void;
}

export const HeaderView = observer((props: IHeaderViewProps) => {
  return (
    <div className={styles.Header}>
      <div className={classNames(
        styles.Container,
        {[styles.SidebarIsOpen]: props.isSidebarVisible}
      )}>
        <Logo
          src={logoSrc}
          variant={LogoVariant.noText}
          isSidebarOpen={props.isSidebarVisible}
        />
        <MobileOrderButton
          createOrder={props.createOrder}
          onOrderButtonClick={props.onButtonClick}
          isSidebarOpen={props.isSidebarVisible}
        />
        <UserDetailsView
          onUserAccountClick={props.onButtonClick}
          user={props.user}
          isSidebarOpen={props.isSidebarVisible}
        />
      </div>
    </div>
  );
});
