import { autorun, reaction } from "mobx";
import { ApiErrorResponse } from "@api/Model/ApiErrorResponse";
import { isApiErrorResponse } from "../Utils";

export * from "./forms";

export interface IProperty<T> {
  get: () => T;
  set: (v: T) => void;
}

export interface IBindableProperty<T> {
  get: () => T;
  set: (v: T) => void;
}

export function property<T, K extends keyof T>(model: T, propertyName: K) {
  return {
    get: () => model[propertyName],
    set: (value: T[K]) => (model[propertyName] = value)
  };
}

export function bindTo<T>(property: IBindableProperty<T>): { value: T; onChange: (value: T) => void } {
  return {
    value: property.get(),
    onChange: (value: T) => property.set(value)
  };
}

export function bindToEditor<T>(editor: { value: T; commit: () => void }) {
  return {
    value: editor.value,
    onChange: (value: T) => (editor.value = value),
    onCommit: () => editor.commit()
  };
}

export type Loading = "Loading";

export type ApiBound<T> = T | Loading | ApiErrorResponse;

type LoadingType = "Loading";

type LoadingErrorType = "LoadingError";

export type Deferred<T> = T | LoadingType | LoadingErrorType;

export function bindToApi<T>(p: IBindableProperty<ApiBound<T>>, api: () => Promise<T>, delay?: number): void {
  let reqId = 0;

  autorun(
    async () => {
      reqId++;
      const currentReqId = reqId;

      p.set("Loading");

      try {
        const v = await api();

        if (currentReqId == reqId) {
          p.set(v);
        }
      } catch (e) {
        p.set({
          errors: [e]
        });
      }
    },
    {
      delay: delay || 250
    }
  );
}

export function loadedOrDefault<T>(model: ApiBound<T>): T | undefined {
  if (model == "Loading" || isApiErrorResponse(model)) return undefined;
  return model;
}

export function ensureLoaded<T>(expr: () => ApiBound<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    let value = expr();
    if (isLoaded(value)) {
      resolve(value);
      return;
    }

    let dispose = reaction(expr, model => {
      dispose();

      if (isApiErrorResponse(model)) reject(model);
      else if (isLoaded(model)) resolve(model);
    });
  });
}

export function isLoaded<T>(model: ApiBound<T>): model is T {
  if (model == undefined || model == "Loading") return false;
  if (isApiErrorResponse(model)) return false;
  return true;
}

export function bindDataSourceTo<T>(dataSource: ApiBound<T[]>) {
  return {
    loading: dataSource == "Loading",
    dataSource: isLoaded(dataSource) ? dataSource : undefined
  };
}
