import { Page } from "@mvvm/Page";
import { action, observable } from "mobx";
import { UserDto } from "@api/Users/Model/UserDto";
import { getCurrentUser } from "@api/Users/GetCurrentUserRequest";
import { ApiBound, bindToApi, ensureLoaded, property, isLoaded } from "@mvvm/index";
import { TokenResult } from "../../../data/Infrastructure/Auth/TokenResult";
import { AuthPage } from "../Auth/AuthPage";
import { authentication } from "../../../auth/Authentication";
import { Command, command } from "@mvvm/commands";
import { CreateOrderPage } from "../CreateOrder/CreateOrderPage";
import { RentalOrdersPage } from "../RentalOrders/RentalOrdersPage";

export interface AppContext {
  user: UserDto;
}

export class ApplicationShell extends Page {
  @observable appContext: ApiBound<AppContext> = "Loading";
  @observable user: ApiBound<UserDto> = "Loading";

  private async getAppContext(): Promise<AppContext> {
    const user = await ensureLoaded(() => this.user);

    return {
      user
    };
  }

  goToTestPage: Command;

  constructor() {
    super();

    authentication.isLoggedIn().then(res => {
      if (res) {
        this.loadUserData();
      }
    });

    this.goToTestPage = command(this.navigateToRentalOrdersPage);
  }

  private loadUserData = () => {
    bindToApi(property(this, "user"), () => getCurrentUser({}));
    bindToApi(property(this, "appContext"), () => this.getAppContext());
  };
  
  navigateToCreateOrderPage = () => {
    return this.showChildPage(new CreateOrderPage(command(this.showOrdersPage)));
  };

  navigateToRentalOrdersPage = () => {
    return this.showChildPage(new RentalOrdersPage(command(this.showOrdersPage)));
  };

  @action.bound
  private showOrdersPage(): Promise<RentalOrdersPage> {
    return this.showChildPage(new RentalOrdersPage(command(this.showOrdersPage)));
  }

  @action.bound
  onLogout = () => {
    this.user = "Loading";
  };

  _authTask: Promise<AuthPage> | undefined = undefined;
  showAuthPage = async (onAuthenticated?: (tokenResult: TokenResult) => void) => {
    if (!this._authTask) {
      const childPage = this.childPage;

      if (!onAuthenticated) {
        onAuthenticated = (tokenResult => {
          authentication.storeTokens(tokenResult);
        });
      }

      this._authTask = this.showChildPage(new AuthPage(async (tokenResult) => {
          if (onAuthenticated) {
            onAuthenticated(tokenResult);
          }
          setImmediate(async () => {
            this.loadUserData();
            await ensureLoaded(() => this.appContext);
            if (childPage && !(childPage as AuthPage).isAuthPage) {
              await this.showChildPage(childPage);
            } else {
              location.href = "/";
            }
            this._authTask = undefined;
          });
      }));
    }
    return this._authTask;
  };
}
