import * as React from "react";
import {observer} from "mobx-react";
import {ContentView} from "@mvvm/ContentView";
import {ApplicationShell} from "./ApplicationShell";
import * as styles from "./ApplicationShellView.less";
import {withDefaultPage} from "../../../Utils";
import {AuthPage} from "../Auth/AuthPage";
import {SidebarLayout} from "./SidebarLayout";
import {RentalOrdersPage} from "../RentalOrders/RentalOrdersPage";

export const ApplicationShellView = observer(({model}: { model: ApplicationShell }) => (
    <div className={styles.Container}>
      <div className={styles.pageContent}>
        {model.childPage && (model.childPage as AuthPage).isAuthPage && <ContentView content={model.childPage}/>}
        {!(model.childPage as AuthPage)?.isAuthPage && <SidebarLayout model={model}/>}
        {withDefaultPage<RentalOrdersPage>(model, model.navigateToRentalOrdersPage)}
      </div>
    </div>
));
