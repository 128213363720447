import { Page } from "@mvvm/Page";
import { action, computed, observable } from "mobx";
import { CreateOrderStep1Page } from "./Steps/Step1/CreateOrderStep1Page";
import { CreateOrderStep2Page, ProductRentalRow } from "./Steps/Step2/CreateOrderStep2Page";
import { CreateOrderSummaryPage } from "./Steps/Summary/CreateOrderSummaryPage";
import { Command } from "@mvvm/commands";
import { FormStep } from "./FormStep";
import { createCustomerRentalOrder, CreateCustomerRentalOrderRequest } from "@api/Orders/RentalOrders/CreateCustomerRentalOrderRequest";
import { throwOnApiError } from "apiClient";
import { RentalOrderInfoDto } from "@api/Orders/RentalOrders/Model/RentalOrderInfoDto";
import { RentalOrderProductLineDto } from "@api/Orders/RentalOrders/Model/RentalOrderProductLineDto";

interface FormStepInfo {
  page: FormStep;
  displayed: boolean;
  header: string;
}

export type RentalOrderFormInfo = {
  info: RentalOrderInfoDto;
  productLines: RentalOrderProductLineDto[];
};
export type RentalOrderWithProducts = {
  data: RentalOrderFormInfo;
  selectedProducts: ProductRentalRow[];
};

export class CreateOrderPage extends Page {
  dismiss: Command;
  nextStep: Command;
  submitOrder: Command;

  @observable step = 0;
  steps: FormStepInfo[] = [];

  @observable submitted: boolean = false;

  @observable order: RentalOrderWithProducts = {
    data: {
      info: {
        id: 0,
        version: 0,
        entrepreneur: "",
        fulfillmentDate: null,
        shippingAddress: "",
        project: "",
        projectId: "",
        contactPerson: { name: "", phoneNumber: "" },
        notes: "",
        invoiceReference: "",
      },
      productLines: []
    },
    selectedProducts: []
  };

  @computed get isLastStep() {
    return this.childPage == this.steps[this.steps.length - 1].page;
  }

  constructor(public showAllOrders: Command) {
    super();
    this.dismiss = new Command<any>(this.resetPageFunc);
    this.nextStep = new Command<any>(this.nextStepFunc);
    this.submitOrder = new Command<any>(this.submitOrderFunc);
  }

  protected async onActivated() {
    this.resetPageFunc();
  }

  @action.bound
  private resetPageFunc() {
    this.submitted = false;
    this.step = 0;
    this.steps = [
      { page: new CreateOrderStep1Page(this.order.data), displayed: true, header: "Details and delivery" },
      { page: new CreateOrderStep2Page(this.order), displayed: false, header: "Products" },
      { page: new CreateOrderSummaryPage(this.order), displayed: false, header: "Summary" }
    ];
    this.showChildPage(this.steps[0].page);
  }

  @action.bound
  async setStep(step: number) {
    if (step < this.steps.length) {
      const currentPage = this.steps[this.step];
      const result = step < this.step || (await currentPage.page.validateAndSubmit());
      if (result) {
        this.step = step;
        const newPage = this.steps[this.step];
        newPage.displayed = true;
        this.showChildPage(newPage.page);
      }
    }
  }

  @action.bound
  private async nextStepFunc() {
    this.setStep(this.step + 1);
  }

  @action.bound
  private async submitOrderFunc() {
    const { productLines, info: rentalOrderInfo } = this.order.data;
    const request: CreateCustomerRentalOrderRequest = {
      rentalOrderInfo,
      productLines
    };
    await throwOnApiError(createCustomerRentalOrder(request));
    this.submitted = true;
  }
}
