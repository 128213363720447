import {Modal} from "antd";

export interface IModalService{
    showError(error: string): void;
}

class ModalService implements IModalService{
    showError(error: string) {
      Modal.error({
        title: 'Error!',
        content: error,
      });
    }
}

const instance: IModalService = new ModalService();
export default instance;