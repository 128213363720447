import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenResult} from "../../Infrastructure/Auth/TokenResult"

export interface LoginRequest {
    emailAddress: string
    password: string
}
export const LoginRequestMetadata: {[key in keyof Required<LoginRequest>]: LoginRequestPropertyMetadata} = {
    emailAddress: {
        $name: "emailAddress",
        $type: "string",
        required: true,
    },
    password: {
        $name: "password",
        $type: "string",
        required: true,
    },
}
export interface LoginRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const login = (loginRequest: LoginRequest): Promise<TokenResult | ApiErrorResponse> => {
    return send("Login", loginRequest, true)
}
