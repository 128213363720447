import * as React from "react";
import { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { RentalOrdersPage } from "./RentalOrdersPage";
import * as styles from "./RentalOrdersPageView.less";
import { Tabs, Typography } from "antd";
import { PaginatedAntTable } from "../../../components/Tables/AntTable";
import { buildColumnsDefinition, columnConverter } from "../../../GridUtils";
import { CustomerAgentRentalOrderListItemDtoMetadata } from "@api/Orders/RentalOrders/Model/CustomerAgentRentalOrderListItemDto";
import { FiltersBar } from "./FiltersBar";
import { ContentView } from "@mvvm/ContentView";
import classNames from "classnames";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { StatusLabel } from "../../../components/StatusLabel/StatusLabel";
import { toNorwegianDateFormat, toNorwegianNumberFormat } from "../../../Utils";

const { Link } = Typography;
const { TabPane } = Tabs;

export const RentalOrdersPageView = observer(({ model }: { model: RentalOrdersPage }) => {
  const isDesktop = window.matchMedia("(min-width: 1600px)");
  const getColumns = (isShippedTab: boolean, isInPanelMode: boolean) =>
    buildColumnsDefinition(CustomerAgentRentalOrderListItemDtoMetadata, columnConverter, {
      submissionDate: {
        render: (dateString: string) => toNorwegianDateFormat(dateString),
        visible: !isInPanelMode
      },
      fulfillmentDate: {
        render: (dateString: string) =>
          isInPanelMode ? <span className={styles.DateSpan}>{toNorwegianDateFormat(dateString)}</span> : toNorwegianDateFormat(dateString),
        visible: true,
        width: isInPanelMode ? "30%" : "auto"
      },
      number: {
        render: (orderNumber: string, order: any) =>
          isInPanelMode ? (
            <Link onClick={() => model.showOrderDetails(order.id)}>
              <span className={styles.OrderNumberSpan}>{orderNumber}</span>
            </Link>
          ) : (
            <Link onClick={() => model.showOrderDetails(order.id)}>{orderNumber}</Link>
          ),
        align: "center",
        visible: true,
        width: isInPanelMode ? (isDesktop ? "30%" : "20%") : "auto"
      },
      entrepreneur: {
        visible: !isInPanelMode
      },
      project: {
        visible: true
      },
      projectId: {
        visible: true
      },
      customerDepartmentName: {
        visible: !isInPanelMode
      },
      productsDisplay: {
        align: "center",
        visible: !isInPanelMode && isShippedTab
      },
      statusDisplay: {
        render: (statusDisplay: string, item: any) => <StatusLabel statusDisplay={statusDisplay} status={item.status} />,
        align: "center",
        visible: !isInPanelMode
      },
      wasteSaved: {
        title: "Waste [kg]",
        render: (statusDisplay: number) => toNorwegianNumberFormat(statusDisplay, 1),
        align: "center",
        visible: !isInPanelMode
      },
      cost: {
        render: (cost: number) => `${toNorwegianNumberFormat(cost, 1)} NOK`,
        align: "center",
        visible: false
      }
    });

  const tableProps = {
    className: classNames({ [styles.RentalOrdersList]: !model.childPage }, { [styles.RentalOrdersListInPanelMode]: !!model.childPage }),
    rowClassName: ({ id }: { id: number }) => (id === model.selectedOrderId ? "ant-table-row-selected" : ""),
    onRow: ({ id }: { id: number }) => ({
      onClick: () => {
        model.showOrderDetails(id);
      }
    })
  };

  const onEsc = useCallback(event => {
    if (event.keyCode === 27) {
      model.removeChildPage();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onEsc, false);

    return () => {
      document.removeEventListener("keydown", onEsc, false);
    };
  }, []);

  return (
    <div className={classNames(styles.RentalOrdersPage, { [styles.RentalOrdersPageInPanelMode]: !!model.childPage })}>
      <div className={classNames(styles.Content, { [styles.ContentInPanelMode]: !!model.childPage })}>
        {!model.childPage && (
          <h2 className={styles.Header} onClick={model.removeChildPage}>
            Orders
          </h2>
        )}

        {!!model.childPage && (
          <Link className={styles.LinkBackToList} onClick={model.removeChildPage}>
            <ArrowLeftOutlined /> Go back to list
          </Link>
        )}
        <Tabs defaultActiveKey="1">
          <TabPane tab="In process" key="1">
            <div className={classNames(styles.ScrollContainer, styles.ScrollContainerNoFilters)}>
              <PaginatedAntTable
                list={model.inProcessItems}
                columns={getColumns(false, !!model.childPage).filter(col => col.visible)}
                {...tableProps}
              />
            </div>
          </TabPane>
          <TabPane tab="Active" key="2">
            <FiltersBar filter={model.rentalOrderFilter} panelMode={!!model.childPage} />
            <div className={styles.ScrollContainer}>
              <PaginatedAntTable
                list={model.activeItems}
                columns={getColumns(true, !!model.childPage).filter(col => col.visible)}
                {...tableProps}
              />
            </div>
          </TabPane>
          <TabPane tab="Completed" key="3">
            <FiltersBar filter={model.rentalOrderFilter} panelMode={!!model.childPage} />
            <div className={styles.ScrollContainer}>
              <PaginatedAntTable
                list={model.completedItems}
                columns={getColumns(true, !!model.childPage).filter(col => col.visible)}
                {...tableProps}
              />
            </div>
          </TabPane>
          <TabPane tab="All" key="4">
            <FiltersBar filter={model.rentalOrderFilter} panelMode={!!model.childPage} />
            <div className={styles.ScrollContainer}>
              <PaginatedAntTable
                list={model.allItems}
                columns={getColumns(true, !!model.childPage).filter(col => col.visible)}
                {...tableProps}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <ContentView content={model.childPage} />
    </div>
  );
});
