import * as React from "react";
import 'mobx-react-lite/batchingForReactDom';
import * as ReactDOM from "react-dom";
import "normalize.css";
import "./styles/theme.less";
import {authentication} from "./auth/Authentication";
import {createBrowserHistory} from "history";
import {applicationShell, applicationShellRoute} from "./routes";
import {ContentView} from "@mvvm/ContentView";
import {viewRegistration} from "./ViewRegistration";
import {enableLogging, previewUrlService} from "./Utils";
import {TokenResult} from "./data/Infrastructure/Auth/TokenResult";
import {AuthPage} from "./features/Pages/Auth/AuthPage";

viewRegistration();

const loggingEnabled = false;
if (loggingEnabled) {
  enableLogging();
}

authentication.setAuthorizeCallback(() => {
  return new Promise<TokenResult>((resolve => {
    applicationShell.showAuthPage(resolve);
  }));
});
authentication.setLogoutCallback(() => {
  applicationShell.onLogout();
});


const render = (children: JSX.Element | JSX.Element[]) => {
  return ReactDOM.render(children, document.getElementById("app"));
};

const bootstrapApplication = async () => {
  const shellBinding = await applicationShellRoute.bind(createBrowserHistory());
  const page = shellBinding.page.childPage;
  const skipLogin = !!page && (page as AuthPage).isAuthPage;
  await authentication.run(
    async () => {
      render(<ContentView content={shellBinding.page}/>);
    },
    skipLogin
  );
};

bootstrapApplication();


