import { send } from "../../../api";
import { ApiErrorResponse } from "../../../Api/Model/ApiErrorResponse";


export interface DeleteRentalOrderRequest {
    orderId: number
}
export const DeleteRentalOrderRequestMetadata: {[key in keyof Required<DeleteRentalOrderRequest>]: DeleteRentalOrderRequestPropertyMetadata} = {
    orderId: {
        $name: "orderId",
        $type: "NorskGjenbruk.Model.Reference<NorskGjenbruk.Model.RentalOrder>",
    },
}
export interface DeleteRentalOrderRequestPropertyMetadata {
    $name: string
    $type: string
}
export const deleteRentalOrder = (deleteRentalOrderRequest: DeleteRentalOrderRequest): Promise<any | ApiErrorResponse> => {
    return send("DeleteRentalOrder", deleteRentalOrderRequest, false)
}
