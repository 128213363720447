import * as React from "react";
import * as styles from "./Sticker.less";
import classNames from "classnames";

interface ISticker extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  colorVariant: StickerVariant;
}

export enum StickerVariant {
  green,
  blue,
  grey
}

export const Sticker = (props: ISticker) => {
  return (
    <aside className={
      classNames(
        styles.Sticker,
        props.className,
       { 
         [styles.StickerBlue] : props.colorVariant == StickerVariant.blue,
         [styles.StickerGreen] : props.colorVariant == StickerVariant.green,
         [styles.StickerGrey] : props.colorVariant == StickerVariant.grey,
       }
      )
    }>
      {props.children}
    </aside>
  );
};
