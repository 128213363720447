import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {PasswordPolicy} from "./Models/PasswordPolicy"

export interface GetPasswordPolicyRequest {
}
export const GetPasswordPolicyRequestMetadata: {[key in keyof Required<GetPasswordPolicyRequest>]: GetPasswordPolicyRequestPropertyMetadata} = {
}
export interface GetPasswordPolicyRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getPasswordPolicy = (getPasswordPolicyRequest: GetPasswordPolicyRequest): Promise<PasswordPolicy | ApiErrorResponse> => {
    return send("GetPasswordPolicy", getPasswordPolicyRequest, true)
}
