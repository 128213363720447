import * as React from 'react';

interface FlexViewProps {
  column: boolean;
  wrap: boolean;
  justifyContent: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  alignItems: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  flex?: number | [number, number] | [number, number, string];
  style?: React.CSSProperties;
  children?: React.ReactNode
  className?: string;
}

export class FlexView extends React.PureComponent<FlexViewProps> {

  render() {
    const { column, wrap, justifyContent, alignItems, flex, children, style, className } = this.props;

    return (
      <div
        style={{
          ...style,
          display: "flex",
          flexDirection: column ? "column" : "row",
          flexWrap: wrap ? "wrap" : "nowrap",
          justifyContent: justifyContent,
          alignItems: alignItems,
          flex: flex ? getFlex(flex) : undefined
        }}
        className={className}
      >
        {children}
      </div>
    );
  }

  static defaultProps = {
    column: false,
    wrap: false,
    justifyContent: "flex-start",
    alignItems: "stretch",
  }
}

interface FlexProps {
  order: number;
  flex: number | [number, number] | [number, number, string]
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
}

export class Flex extends React.PureComponent<FlexProps> {

  render() {

    const { flex, style, children, className } = this.props;

    return (
      <div
        style={{ ...style, flex: getFlex(flex) }}
        className={className}
      >
        {children}
      </div>
    )
  }

  static defaultProps = {
    order: 0,
    flex: 0
  }
}

const getFlex = (flex: number | [number, number] | [number, number, string]) => {
  if (typeof flex === "number") {
    return flex;
  }
  if (Array.isArray(flex) && flex.length === 2) {
    return `${flex[0]} ${flex[1]}`;
  }
  if (Array.isArray(flex) && flex.length === 3) {
    return `${flex[0]} ${flex[1]} ${flex[2]}`;
  }

  return 0;
}