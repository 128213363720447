import * as React from "react";
import {Button} from "antd";
import * as styles from "./ButtonNewOrder.less";
import {CustomIcon} from "@components";

interface IButtonNewOrder {
  createOrder: () => void;
  text: string;
}

export const ButtonNewOrder = ({text,createOrder}: IButtonNewOrder) => {
  return (
    <div className={styles.Container} onClick={createOrder}>
      <CustomIcon className={styles.IconCreateOrder} type={'icon-create-order'}/>
      <Button
        className={styles.ButtonNewOrder}
      >
        {text}
      </Button>
    </div>
  )
};