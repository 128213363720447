import * as React from "react";
import { Sticker } from "../Sticker/Sticker";
import { Col } from "antd";
import { StickerVariant } from "src/components/Sticker/Sticker";
import { toNorwegianNumberFormat } from "src/Utils";
import * as styles from "./WasteReductionCard.less";

interface IWasteReductionCard {
  wasteSaved: number;
}

export const WasteReductionCard = (props: IWasteReductionCard) => {
  return (
    <Col span={24} order={3} sm={{ span: 11, order: 2 }} md={{ span: 11, order: 2 }} lg={{ span: 7, order: 3 }}>
      <Sticker colorVariant={StickerVariant.grey} className={styles.WasteSaved}>
        <p>You reduced</p>
        <p className={styles.WasteSavedValue}>{toNorwegianNumberFormat(props.wasteSaved, 1)} kg</p>
        <p>of waste*</p>
        <p>*Waste = (Plastic, wood and nails)</p>
      </Sticker>
    </Col>
  );
};
