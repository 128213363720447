import * as React from "react";
import * as styles from './UserDetails.less';
import {CustomIcon} from "@components";
import {Popover} from "antd";
import {ApiBound, isLoaded} from "@mvvm/index";
import {useState} from "react";
import {HeaderMenuView} from "../HeaderMenu/HeaderMenuView";
import {UserDto} from "@api/User/Model/UserDto";
import classNames from "classnames";

interface IUserDetailsProps {
  user: ApiBound<UserDto>;
  onUserAccountClick: () => void;
  isSidebarOpen: boolean,
}

export function UserDetailsView({user, onUserAccountClick, isSidebarOpen}: IUserDetailsProps) {
  const [visible, toggleMenu] = useState(false);

  const handleVisibleChange = () => {
    toggleMenu(!visible);
  };

  const content = (
    <div onClick={handleVisibleChange}>
      <HeaderMenuView
        user={user}
        handleVisibleChange={handleVisibleChange}
        onUserAccountClick={onUserAccountClick}
      />
    </div>
  );

  return (
    <div className={classNames(
      styles.UserDetailsContainer,
      {[styles.SidebarOpen]: isSidebarOpen}
    )}>
      <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        placement="bottomRight"
      >
        <>
          {isLoaded(user) &&
          <div onClick={() => handleVisibleChange()}>
            <div className={classNames(
              styles.UserDetails,
            )} style={{display: isSidebarOpen ? 'flex' : 'none'}}>
              <div className={styles.UserDetailsText}>
                <span className={styles.UserDetailsTextLine}>{user.name}</span>
              </div>
              <CustomIcon type={'icon-user'}/>
            </div>
          </div>
          }
        </>
      </Popover>
    </div>
  )
}