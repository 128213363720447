import * as React from "react";
import * as styles from "./VerticalMenu.less";
import "simplebar";

export const VerticalMenu = React.forwardRef<HTMLDivElement, { children?: React.ReactNode }>((props, ref) => {
  return (
    <div className={styles.menuContainer} data-simplebar ref={ref}>
      <div>{props.children}</div>
    </div>
  );
});
