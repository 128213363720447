import * as React from "react";
import {observer} from "mobx-react";
import {ActivateAccountPage} from "./ActivateAccountPage";
import {TextInput} from "@components";
import {bindToCommand} from "@mvvm/commands";
import {AntField, AntForm} from "../../../../components/Forms/AntForm";
import {SubmitButton} from "../../../../components/SubmitButton/SubmitButton";
import {Spacer} from "../../../../components/Spacer/Spacer";

export const ActivateAccountPageView = observer(({model}: { model: ActivateAccountPage }) => (
  <>
    {model.validData === undefined && <h2>loading...</h2>}
    {model.validData === false && <>
      <h2>Invalid token</h2>
      <a href="mailto:contact@looping.no?subject=Looping Customer Portal - access request">Request access</a>
    </>}

    {model.validData && !model.succeeded &&
    <>
      <Spacer>
        <p>
          Please activate you account
        </p>
      </Spacer>
      <AntForm form={model.form} onFinish={model.sendRequestFunc}>
        {fields => (
          <>
            <AntField field={fields.name}>
              {props => <TextInput {...props} placeholder="Display name"/>}
            </AntField>
            <p className='static-input'>{model.emailAddress}</p>
            <p className='static-input'>{model.company}</p>
            <AntField field={fields.password}>
              {props => <TextInput {...props} type="password" placeholder="Password"/>}
            </AntField>
            <AntField field={fields.passwordToConfirm}>
              {props => <TextInput {...props} type="password" placeholder="Confirm password"/>}
            </AntField>
            <SubmitButton text={"Create Account"}/>
          </>
        )}
      </AntForm>
    </>}
    {model.succeeded &&
    <h2>You can now <a {...bindToCommand(model.goToLoginPage)}>Login</a> using your credentials</h2>
    }
  </>
));