import {RentalStatus} from "../../../Externals"

export interface CustomerAgentRentalOrderRentalLineDto {
    id: number
    orderedProductName: string
    inventoryItemSerialNo: string
    endDate?: any
    status: RentalStatus
    statusDisplay: string
    duration: number
}
export const CustomerAgentRentalOrderRentalLineDtoMetadata: {[key in keyof Required<CustomerAgentRentalOrderRentalLineDto>]: CustomerAgentRentalOrderRentalLineDtoPropertyMetadata} = {
    id: {
        $name: "id",
        $type: "int",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    orderedProductName: {
        $name: "orderedProductName",
        $type: "string",
        gridColumn: {
            header: "Name",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    inventoryItemSerialNo: {
        $name: "inventoryItemSerialNo",
        $type: "string",
        gridColumn: {
            header: "Serial number",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    endDate: {
        $name: "endDate",
        $type: "System.DateTimeOffset?",
        gridColumn: {
            header: "End date",
        },
        sieve: {
            canFilter: true,
            canSort: true,
        },
    },
    status: {
        $name: "status",
        $type: "NorskGjenbruk.Model.RentalStatus",
        gridColumn: {
            hidden: true,
        },
        sieve: {
            canSort: true,
            name: "StatusDisplay",
        },
    },
    statusDisplay: {
        $name: "statusDisplay",
        $type: "string",
        gridColumn: {
            header: "Status",
        },
    },
    duration: {
        $name: "duration",
        $type: "int",
        gridColumn: {
            header: "Duration",
        },
        sieve: {
            canSort: true,
            name: "StatusDisplay",
        },
    },
}
export interface CustomerAgentRentalOrderRentalLineDtoPropertyMetadata {
    $name: string
    $type: string
    gridColumn?: {
        hidden?: boolean;
        header?: string;
    },
    sieve?: {
        canFilter?: boolean;
        canSort?: boolean;
        name?: string;
    },
}
