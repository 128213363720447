import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";

import {TokenInfo} from "../../Infrastructure/Auth/TokenInfo"

export interface RefreshTokenRequest {
    refreshToken: string
}
export const RefreshTokenRequestMetadata: {[key in keyof Required<RefreshTokenRequest>]: RefreshTokenRequestPropertyMetadata} = {
    refreshToken: {
        $name: "refreshToken",
        $type: "string",
        required: true,
    },
}
export interface RefreshTokenRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const refreshToken = (refreshTokenRequest: RefreshTokenRequest): Promise<TokenInfo | ApiErrorResponse> => {
    return send("RefreshToken", refreshTokenRequest, true)
}
