import {refreshToken as refreshTokenRequest} from "@api/Auth/RefreshTokenRequest";
import {enableLogger, isApiErrorResponse, loggerFactory} from "../Utils";
import {TokenInfo} from "../data/Infrastructure/Auth/TokenInfo";
import {TokenResult} from "../data/Infrastructure/Auth/TokenResult";
import {Token} from "path-to-regexp";

interface AuthenticationConfig {
  cacheLocation: "localStorage" | "sessionStorage" | undefined;
}

const writeLog = loggerFactory("Authentication");
enableLogger("Authentication");

class Authentication {
  private readonly storage: Storage;

  private accessTokenKey = "access_token";
  private refreshTokenKey = "refresh_token";
  private accessTokenExpirationTime = "access_token_expiration";
  private refreshTokenExpirationTime = "refresh_token_expiration";

  constructor(public config: AuthenticationConfig) {
    this.storage = this.config.cacheLocation === "localStorage" ? localStorage : sessionStorage;
  }

  setAuthorizeCallback(getTokens: () => Promise<TokenResult>) {
    this._getTokens = getTokens;
  }

  setLogoutCallback(onLogout: () => void) {
    this._onLogout = onLogout;
  }

  // initial values,
  _getTokens: (() => Promise<TokenResult>) = async () => null as any as TokenResult;
  _onLogout: (() => void) = () => {
  };

  async run(app: () => Promise<void>, skipLogin: boolean) {
    await app();
    if (!skipLogin && !await this.isLoggedIn()) {
      await this.login();
    }
  }

  async isLoggedIn() {
    writeLog("isLoggedIn -> started");
    const token = await this.retrieveAccessToken();
    writeLog("isLoggedIn -> ended", token);
    return !!token;
  }

  _loginTask: any = null;

  async login() {
    if (!this._loginTask) {
      this._loginTask = this._getTokens()
        .then(x => {
          this._loginTask = null;
          return x;
        });
    }
    const tokens = await this._loginTask;
    this.storeTokens(tokens);
  }

  async logout() {
    this.storage.removeItem(this.accessTokenKey);
    this.storage.removeItem(this.accessTokenExpirationTime);
    this.storage.removeItem(this.refreshTokenKey);
    this.storage.removeItem(this.refreshTokenExpirationTime);
    this._onLogout && await this._onLogout();
    await this.login();
  }

  public storeTokens(tokens: TokenResult) {
    if (!tokens) {
      writeLog("storeTokens -> passed tokens are null");
      return;
    }
    this.storeAccessToken(tokens.accessToken);
    this.storeRefreshToken(tokens.refreshToken);
    writeLog("storeTokens -> tokens stored");
  }

  token(tokenKey: string, expirationKey: string) {
    const token = this.storage.getItem(tokenKey);
    if (!token) {
      return null;
    }
    const expiration = Number(this.storage.getItem(expirationKey));
    if (isNaN(expiration) || expiration < Date.now() + 10000) {
      // if it is valid for less than 10 seconds we consider token invalid, so we'll have time to refresh
      return null;
    }
    return token;
  }

  get accessToken() {
    return this.token(this.accessTokenKey, this.accessTokenExpirationTime);
  }

  get refreshToken() {
    return this.token(this.refreshTokenKey, this.refreshTokenExpirationTime);
  }

  private storeAccessToken(token: TokenInfo) {
    this.storage.setItem(this.accessTokenKey, token.token);
    this.storage.setItem(this.accessTokenExpirationTime, (Date.now() + token.expiresIn * 1000).toString());
  }

  private storeRefreshToken(token: TokenInfo) {
    this.storage.setItem(this.refreshTokenKey, token.token);
    this.storage.setItem(this.refreshTokenExpirationTime, (Date.now() + token.expiresIn * 1000).toString());
  }

  _getAccessToken: any;

  async getAccessToken() {
    if (!this._getAccessToken) {
      writeLog("getAccessToken -> promise created");
      this._getAccessToken = new Promise<string | null>(async resolve => {
        writeLog("getAccessToken -> retrieveAccessToken start");
        const accessToken = await this.retrieveAccessToken();
        writeLog("getAccessToken -> retrieveAccessToken end");
        if (!accessToken) {
          writeLog("getAccessToken -> no access token");
          await this.login();
          writeLog("getAccessToken -> logged in");
        }
        resolve(this.accessToken);
      }).then(x => {
        writeLog("getAccessToken -> promise resolved");
        this._getAccessToken = null;
        return x;
      });

    }
    return this._getAccessToken;
  }

  _accessTokenPromise: Promise<string | null> | undefined = undefined;

  async retrieveAccessToken() {
    if (!this._accessTokenPromise) {
      this._accessTokenPromise = new Promise<string | null>(async (resolve, rejects) => {
        writeLog("retrieveAccessToken -> promise created");
        const accessToken = this.accessToken;
        if (!accessToken) {
          if (!this.refreshToken) {
            writeLog("retrieveAccessToken -> no valid refresh token");
            resolve(null);
            return;
          }
          const res = await refreshTokenRequest({refreshToken: this.refreshToken});
          if (isApiErrorResponse(res)) {
            writeLog("retrieveAccessToken -> invalid refresh token, refreshing failed");
            resolve(null);
            return;
          }
          this.storeAccessToken(res);
          writeLog("retrieveAccessToken -> stored new access token");
          resolve(this.accessToken);
          return;
        } else {
          writeLog("retrieveAccessToken -> current access token is still valid");
          resolve(accessToken);
        }
      })
        .then(res => {
          writeLog("retrieveAccessToken -> promise resolved");
          this._accessTokenPromise = undefined;
          return res;
        });
    }

    return this._accessTokenPromise;
  }
}

const a = new Authentication({
  cacheLocation: "localStorage"
});


export const authentication = a;
