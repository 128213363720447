import { TableColumnProps } from "./components/Tables/AntTable";

export const buildColumnsDefinition = <TRecord, TMetadata extends { [key in keyof TRecord]: P }, P>(metadata: TMetadata,  mapper: (prop: any) => TableColumnProps<TRecord> | null, customize? : { [key in keyof TRecord] : Partial<TableColumnProps<TRecord>> }) => {
    const definitions = [];

    for (let propertyName in metadata) {
      const property = metadata[propertyName];
        let column = mapper(property);
        if (customize && (customize as any)[propertyName])
          column = { ...column, ...(customize as any)[propertyName] };
        
        if (column)
          definitions.push(column);
    }

    return definitions;
}

export const columnConverter = <TRecord>(
  prop: {
    $name : keyof TRecord
    $type: string, 
    gridColumn?: { 
      hidden?: boolean, 
      header?: string, 
      minWidth?: number, 
      maxWidth?: number, 
      isSelectionInvoke?: boolean 
    },
  })
  : TableColumnProps<TRecord> | null => {

  let gridColumn = prop.gridColumn;

  if (gridColumn && gridColumn.hidden)
    return null;

  let column : TableColumnProps<TRecord> = {
    title: gridColumn && gridColumn.header || prop.$name.toString(),
    dataIndex: prop.$name
  };

  return column;
};