import * as React from "react";
import * as styles from "./Spacer.less";
import classNames from "classnames";

interface ISpacer extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Spacer = (props: ISpacer) => {
  return (
    <div className={classNames(styles.Spacer, props.className)} {...props}>
      {props.children}
    </div>
  );
};
