import * as React from "react";
import {observer} from "mobx-react";
import {LoginPage} from "./LoginPage";
import {TextInput} from "@components";
import {AntField, AntForm} from "../../../../components/Forms/AntForm";
import {bindToCommand} from "@mvvm/commands";
import {SubmitButton} from "../../../../components/SubmitButton/SubmitButton";
import {Space} from "antd";
import {Spacer} from "../../../../components/Spacer/Spacer";

export const LoginPageView = observer(({model}: { model: LoginPage }) => (
  <>
    <Spacer>
      <p>
        Please enter your login details
      </p>
    </Spacer>

    <AntForm form={model.form} onFinish={model.loginFunc}>
      {fields => (
        <>
          <AntField field={fields.emailAddress}>
            {props => <TextInput {...props} placeholder="Email address"/>}
          </AntField>
          <AntField field={fields.password}>
            {props => <TextInput {...props} type="password" placeholder="Password"/>}
          </AntField>
          <SubmitButton text={"Log in"}/>
          <Space size={"large"}>
            <p>
              <a href="#" {...bindToCommand(model.goToForgotPasswordPage)}>Forgot password</a>
              <br/>
              Need an account? <a href="mailto:kontakt@looping.no?subject=Looping Customer Portal - access request">Request access</a>
            </p>
            
          </Space>
        </>
      )}
    </AntForm>
  </>
));