import { send } from "../../api";
import { ApiErrorResponse } from "../../Api/Model/ApiErrorResponse";


export interface ActivateAgentRequest {
    userId: string
    name: string
    password: string
    confirmationToken: string
}
export const ActivateAgentRequestMetadata: {[key in keyof Required<ActivateAgentRequest>]: ActivateAgentRequestPropertyMetadata} = {
    userId: {
        $name: "userId",
        $type: "string",
        required: true,
    },
    name: {
        $name: "name",
        $type: "string",
        required: true,
    },
    password: {
        $name: "password",
        $type: "string",
        required: true,
    },
    confirmationToken: {
        $name: "confirmationToken",
        $type: "string",
        required: true,
    },
}
export interface ActivateAgentRequestPropertyMetadata {
    $name: string
    $type: string
    required?: boolean;
}
export const activateAgent = (activateAgentRequest: ActivateAgentRequest): Promise<any | ApiErrorResponse> => {
    return send("ActivateAgent", activateAgentRequest, true)
}
