import { send } from "../../../api";
import { ApiErrorResponse } from "../../../Api/Model/ApiErrorResponse";
import { download } from "../../../api";
import {ListOptions} from "../../GenericList/ListOptions"
import {CustomerAgentRentalOrderFilter} from "./CustomerAgentRentalOrderFilter"

import {CustomerAgentRentalOrderListItemDto} from "./Model/CustomerAgentRentalOrderListItemDto"

export interface GetCustomerAgentRentalOrdersRequest {
    itemId?: number
    options?: ListOptions
    filter?: CustomerAgentRentalOrderFilter
}
export const GetCustomerAgentRentalOrdersRequestMetadata: {[key in keyof Required<GetCustomerAgentRentalOrdersRequest>]: GetCustomerAgentRentalOrdersRequestPropertyMetadata} = {
    itemId: {
        $name: "itemId",
        $type: "NorskGjenbruk.Model.Reference<NorskGjenbruk.Model.RentalOrder>",
        dtoOptional: true,
    },
    options: {
        $name: "options",
        $type: "NorskGjenbruk.Server.Api.GenericList.ListOptions",
        dtoOptional: true,
    },
    filter: {
        $name: "filter",
        $type: "NorskGjenbruk.Server.Api.Orders.RentalOrders.CustomerAgentRentalOrderFilter",
        dtoOptional: true,
    },
}
export interface GetCustomerAgentRentalOrdersRequestPropertyMetadata {
    $name: string
    $type: string
    dtoOptional?: boolean;
}
export const getCustomerAgentRentalOrders = (getCustomerAgentRentalOrdersRequest: GetCustomerAgentRentalOrdersRequest): Promise<CustomerAgentRentalOrderListItemDto[] | ApiErrorResponse> => {
    return send("GetCustomerAgentRentalOrders", getCustomerAgentRentalOrdersRequest, false)
}
export const getCustomerAgentRentalOrdersBlob = (getCustomerAgentRentalOrdersRequest: GetCustomerAgentRentalOrdersRequest, accept: string): Promise<Blob> => {
    return download("GetCustomerAgentRentalOrders", getCustomerAgentRentalOrdersRequest, accept);
}
