import { FormStep } from "../../FormStep";
import { ApiBound, bindToApi, ensureLoaded, isLoaded, property, loadedOrDefault } from "@mvvm/index";
import { action, computed, observable } from "mobx";
import { contextualCommand, ContextualCommand } from "@mvvm/commands";
import { CustomerProductDto } from "@api/Customers/Model/CustomerProductDto";
import { getAvailableProducts } from "@api/Customers/GetAvailableProductsRequest";
import { RentalOrderWithProducts } from "../../CreateOrderPage";

export interface ProductRentalRow {
  product: CustomerProductDto;
  quantity: number | undefined;
}

export class CreateOrderStep2Page extends FormStep {
  @observable availableProducts: ApiBound<CustomerProductDto[]> = "Loading";
  @observable rentalLines: ApiBound<ProductRentalRow[]> = "Loading";

  @computed get totalWasteSaved() {
    return (loadedOrDefault(this.rentalLines))?.reduce((sum, current) => sum + current.product.wasteReduction * (current.quantity ?? 0), 0) ?? 0;
}

  increaseAmount: ContextualCommand<ProductRentalRow>;
  decreaseAmount: ContextualCommand<ProductRentalRow>;

  constructor(private order: RentalOrderWithProducts) {
    super();
    bindToApi(property(this, "availableProducts"), () => getAvailableProducts({}));

    this.increaseAmount = contextualCommand(line => {
      this.changeLineQuantity(line, 1);
    });
    this.decreaseAmount = contextualCommand(
      line => {
        this.changeLineQuantity(line, -1);
      },
      line => line.quantity !== undefined && line.quantity > 0
    );
  }

  @action.bound
  private changeLineQuantity(line: ProductRentalRow, delta: number) {
    if (line.quantity !== undefined) {
      line.quantity += delta;
    } else {
      line.quantity = delta;
    }
    // ant table is not an observer, quick fix
    this.rentalLines = [...(this.rentalLines as ProductRentalRow[])];
  }

  @action.bound
  overrideLineQuantity(line: ProductRentalRow, quantity: number | undefined) {
    line.quantity = quantity;
    // ant table is not an observer, quick fix
    this.rentalLines = [...(this.rentalLines as ProductRentalRow[])];
  }

  protected async onActivated() {
    if (!isLoaded(this.rentalLines)) {
      const products = await ensureLoaded(() => this.availableProducts);
      this.rentalLines = products.map(x => ({
        product: x,
        quantity: 0
      }));
    }
  }

  @action.bound
  async validateAndSubmit(): Promise<boolean> {
    const nonEmptyLines = (await ensureLoaded(() => this.rentalLines)).filter(x => x.quantity !== undefined && x.quantity > 0);

    if (nonEmptyLines.length === 0) {
      this.messages.push({ type: "error", content: "Select at least 1 product" });
      return false;
    }

    const order = this.order.data;
    order.productLines = nonEmptyLines.map(x => ({
      product: x.product.id,
      quantity: x.quantity ?? 0,
      id: 0
    }));
    this.order.selectedProducts = nonEmptyLines;

    return true;
  }
}
