import {Page} from "@mvvm/Page";
import {Command} from "@mvvm/commands";
import {observable} from "mobx";
import {activateAgent, ActivateAgentRequest} from "@api/Customers/ActivateAgentRequest";
import {isApiErrorResponse} from "../../../../Utils";
import {alertDialog} from "@mvvm/dialogs";
import {getActivationInfo} from "@api/Customers/GetActivationInfoRequest";
import {throwOnApiError} from "apiClient";
import {BindableForm, bindableForm} from "@mvvm/forms";
import {areEqual, required} from "@mvvm/forms/validators";

export interface ActivateAgentFormData {
  name: string
  password: string
  emailAddress: string
  company: string
}

export class ActivateAccountPage extends Page {

  @observable submitted: boolean = false;
  @observable succeeded: boolean = false;
  @observable validData?: boolean = undefined;

  @observable emailAddress: string = "";
  @observable company: string = "";

  @observable activateRequest: ActivateAgentRequest = {
    userId: "",
    name: "",
    password: "",
    confirmationToken: "",
  };

  form: BindableForm<ActivateAgentRequest, any> = bindableForm<ActivateAgentFormData>()
    .addField("name", {validator: required()})
    .addField("password", {validator: this.passwordValidator})
    .addCustomField("passwordToConfirm", async dto => "", dto => {
    }, {
      validator: areEqual(() => this.form.fields.password.value, "Passwords does not match")
    })
    .bindTo(() => this.activateRequest);


  constructor(public userId: string,
              public token: string,
              public goToLoginPage: Command,
              public passwordValidator: (p: any | undefined) => true | string[]) {
    super();
    this.submitted = false;
    this.succeeded = false;
  }

  protected async onActivated() {
    const res = await throwOnApiError(getActivationInfo({
      confirmationToken: this.token,
      userId: this.userId
    }));
    const data = res.result;
    if (res.validData && !!data) {
      this.activateRequest = {
        ...this.activateRequest,
        userId: this.userId,
        name: data.name,
        confirmationToken: this.token
      };

      this.company = data.customerName;
      this.emailAddress = data.emailAddress;

      this.validData = true;
    } else {
      this.validData = false;
    }
  }

  sendRequestFunc = async () => {
    if (await this.form.validateAndCommit()) {
      const result = await activateAgent(this.activateRequest);
      if (!isApiErrorResponse(result)) {
        this.succeeded = true;
      } else {
        await this.showModal(alertDialog("Error", result.errors.join("\n"), false));
      }
    }
  };
}