import * as React from "react";
import * as styles from "./StatusLabel.less";
import classNames from "classnames";
import {Tag} from "antd";
import {RentalOrderStatus, RentalStatus} from "@externals";

interface IStatusLabel extends React.HTMLAttributes<HTMLDivElement> {
  status: RentalOrderStatus | RentalStatus;
  statusDisplay: string;
}

export const StatusLabel = ({status, statusDisplay, className}: IStatusLabel) => (
  <Tag className={
    classNames(
      styles.StatusLabel,
      className,
      {
        [styles.Brown]: status === RentalOrderStatus.new,
        [styles.Azure]: status === RentalOrderStatus.confirmed,
        [styles.Green]: status === RentalOrderStatus.completed || status === RentalStatus.ended,
        [styles.Grey]: status === RentalOrderStatus.canceled || status === RentalStatus.canceled,
        [styles.Blue]: status === RentalOrderStatus.fulfilled || status === RentalStatus.started,
      },
    )
  }>
    {statusDisplay}
  </Tag>
);