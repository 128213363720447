import { send } from "../../../api";
import { ApiErrorResponse } from "../../../Api/Model/ApiErrorResponse";

import {CustomerAgentRentalOrderDetailsDto} from "./Model/CustomerAgentRentalOrderDetailsDto"

export interface GetCustomerAgentRentalOrderDetailsRequest {
    id: number
}
export const GetCustomerAgentRentalOrderDetailsRequestMetadata: {[key in keyof Required<GetCustomerAgentRentalOrderDetailsRequest>]: GetCustomerAgentRentalOrderDetailsRequestPropertyMetadata} = {
    id: {
        $name: "id",
        $type: "NorskGjenbruk.Model.Reference<NorskGjenbruk.Model.RentalOrder>",
    },
}
export interface GetCustomerAgentRentalOrderDetailsRequestPropertyMetadata {
    $name: string
    $type: string
}
export const getCustomerAgentRentalOrderDetails = (getCustomerAgentRentalOrderDetailsRequest: GetCustomerAgentRentalOrderDetailsRequest): Promise<CustomerAgentRentalOrderDetailsDto | ApiErrorResponse> => {
    return send("GetCustomerAgentRentalOrderDetails", getCustomerAgentRentalOrderDetailsRequest, false)
}
